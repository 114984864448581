import { useState } from 'react';
import moment from 'moment';
import {
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Tooltip,
  Box,
} from '@mui/material';
import {
  Link,
  MoreVert,
  VisibilityOutlined,
  DeleteOutlineOutlined,
  Update,
} from '@mui/icons-material';
import { DEPTH_SPACE, getBackgroundColor } from '../../../../utils/files.utils';
import { getFileIconByExtension } from '../../../../utils/guards.utils';
import { VaultFile } from 'app/pages/vault/models/vault.types';
import { bytesToMB } from 'utils/files';
import * as styles from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
  file: VaultFile;
  onPreviewFile: (file: VaultFile) => void;
  onRename: (file: VaultFile) => void;
  onDelete: (file: VaultFile) => void;
  onMoveToFolder: (file: VaultFile) => void;
  depth: number;
  isReadOnly: boolean;
};

export const FileRow = ({
  file,
  onPreviewFile,
  onRename,
  onDelete,
  onMoveToFolder,
  depth,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation();
  /* ================== Menu Logic ================== */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const fileType =
    file?.extension === 'link'
      ? t('vault.labels.link')
      : t('vault.labels.file');
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  /* ================== Menu Logic ================== */

  const handlePreviewFile = () => {
    onPreviewFile(file);
  };

  const handleOpenLink = () => {
    if (file?.getUrl) {
      window.open(file?.getUrl, '_blank');
    }
  };

  const handleMoveToFolder = () => {
    onMoveToFolder(file);
  };

  const handleDelete = () => {
    onDelete(file);
  };

  const handleRename = () => {
    onRename(file);
  };

  return (
    <Grid
      display="flex"
      sx={{
        ...styles.container,
        paddingLeft: `${depth * DEPTH_SPACE}px`,
        backgroundColor: getBackgroundColor(file),
      }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid sx={styles.fileColumnStyle}>
        <img
          width="initial"
          height={24}
          src={getFileIconByExtension(file.extension)}
          alt="pdf icon"
        />
        <Typography variant="body1" marginLeft={1} sx={styles.textEllipsis}>
          {file.fileName}
        </Typography>
      </Grid>
      <Grid sx={{ width: '15%', paddingLeft: { xs: 0, xl: 1 } }}>
        <Typography variant="body1">{file?.itemType}</Typography>
      </Grid>
      <Grid sx={{ width: '15%', paddingLeft: { xs: 0, xl: 1 } }}>
        <Typography variant="body1">{t('vault.labels.owner')}</Typography>
      </Grid>
      <Grid sx={{ width: '15%', paddingLeft: 3 }}>
        <Typography variant="body1">
          {moment(file?.lastModified).format('MM/DD/YYYY')}
        </Typography>
      </Grid>
      <Grid sx={{ width: '15%', paddingLeft: 4 }}>
        <Typography variant="body1">
          {bytesToMB.format(file?.size || 0)}
        </Typography>
      </Grid>
      <Grid>
        {file.isProcessing ? (
          <Tooltip title="This file is processing" arrow>
            <Box sx={styles.processingIcon}>
              <Update color="disabled" />
            </Box>
          </Tooltip>
        ) : (
          <IconButton onClick={handleOpenMenu}>
            <MoreVert />
          </IconButton>
        )}
      </Grid>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {file?.extension === 'link' ? (
          <MenuItem onClick={handleOpenLink}>
            <IconButton disableRipple>
              <Link />
            </IconButton>
            <Typography variant="body2">
              {t('vault.labels.openLink')}
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={handlePreviewFile}>
            <IconButton disableRipple>
              <VisibilityOutlined />
            </IconButton>
            <Typography variant="body2">
              {t('vault.labels.previewFile')}
            </Typography>
          </MenuItem>
        )}

        {/*   
    //TODO: Enable when renaming file API is enabled
    <MenuItem onClick={handleRename}>
          <IconButton disableRipple>
            <Edit />
          </IconButton>
          <Typography variant="body2">Rename {fileType}</Typography>
        </MenuItem> */}

        {
          //TODO: Enable when refetching file
          /* <MenuItem>
          <IconButton disableRipple>
            <FileDownloadOutlined />
          </IconButton>
          <Typography variant="body2">{t('vault.labels.download')}</Typography>
        </MenuItem> */
        }
        {/* 
       //TODO: Enable when moving file to folder API is enabled
       <MenuItem onClick={handleMoveToFolder}>
          <IconButton disableRipple>
            <DriveFileMoveOutlined />
          </IconButton>
          <Typography variant="body2">{t('vault.labels.moveToFolder')}</Typography>
        </MenuItem> */}
        {!isReadOnly && (
          <MenuItem onClick={handleDelete}>
            <IconButton disableRipple>
              <DeleteOutlineOutlined />
            </IconButton>
            <Typography variant="body2">{t('vault.labels.delete')}</Typography>
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
};
