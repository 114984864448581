import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { Button } from 'app/components';
import { styles } from './styles';

interface EmptyClientStateProps {
  onGetStarted: () => void;
  isLoading?: boolean;
}

export const EmptyClientState: React.FC<EmptyClientStateProps> = ({
  onGetStarted,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.containerSx}>
      <Box sx={styles.emptyStateContainer}>
        <Typography variant="body1" align="center" sx={styles.titleText}>
          {t('advisors.emptyState.addFirstClient')}
        </Typography>
        <Button
          label={capitalize(t('advisors.getStarted'))}
          variant="contained"
          onClick={onGetStarted}
          disabled={isLoading}
          sx={styles.actionButton}
        />
      </Box>

      <Box sx={styles.textContainer}>
        <Typography
          variant="body2"
          align="center"
          color="text.secondary"
          sx={styles.helperText}
          paragraph
        >
          {t('advisors.emptyState.firstClientSetup')}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          color="text.secondary"
          sx={styles.helperText}
        >
          {t('advisors.emptyState.afterSetupText')}
        </Typography>
      </Box>
    </Box>
  );
};
