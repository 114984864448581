const filterPopoverStyles = {
  paper: {
    width: '198px',
    minHeight: '225px',
    border: '1px solid',
    borderColor: 'secondary.30p',
    borderRadius: '4px',
    p: 0,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px dashed',
    borderColor: 'secondary.30p',
    px: 2,
    py: 1,
  },
  headerTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '12px',
    color: 'primary.main',
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  clearAllText: {
    cursor: 'pointer',
    mr: 1,
    color: 'primary.main',
    fontSize: '12px',
    fontWeight: 500,
  },
  closeButton: {
    p: 0,
    fontSize: '16px',
  },
  filterOptions: {
    px: 2,
    py: 1,
  },
  formControlLabel: {
    display: 'flex',
    margin: 0,
    py: 0.5,
    '.MuiFormControlLabel-label': {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.14px',
    },
    '.MuiCheckbox-root': {
      padding: '4px',
      marginRight: '4px',
    },
  },
  buttonContainer: {
    px: 2,
    pt: 1,
    pb: 2,
  },
  applyButton: {
    textTransform: 'uppercase',
    backgroundColor: '#293439',
    color: 'white',
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: '0.14px',
    height: '30px',
    '&:hover': {
      backgroundColor: '#1e282d',
    },
  },
};

export default filterPopoverStyles;
