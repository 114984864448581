import { CssBaseline, Link, Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { muiTheme } from 'styles/muiTheme';
import { NavBar } from '../NavBar/Loadable';
import { ClientContextBanner } from '../ClientContextBanner';
import 'beautiful-react-diagrams/styles.css';
import style from './style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRoutePath } from 'app/routes/routePath';
import { useSelector } from 'react-redux';
import { selectIsImpersonating } from 'app/features/AddressBookSlice/selectors';

export const AppLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isImpersonating = useSelector(selectIsImpersonating);

  const bannerHeight = 45; // Height of our client context banner

  useEffect(() => {
    if (isImpersonating) {
      document.documentElement.style.setProperty(
        '--content-padding-top',
        `${bannerHeight}px`,
      );
      document.documentElement.style.setProperty(
        '--sidebar-padding-top',
        `${bannerHeight}px`,
      );
    } else {
      document.documentElement.style.setProperty(
        '--content-padding-top',
        `${0}px`, // Reset the padding
      );
      document.documentElement.style.setProperty(
        '--sidebar-padding-top',
        `${0}px`, // Reset the padding
      );
    }
  }, [isImpersonating, bannerHeight]);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <NavBar />
      <ClientContextBanner />
      <Box sx={style.childrenStyle}>{children}</Box>
      <Box sx={style.ribbonBoxStyle}>
        <Typography sx={style.ribbonTextStyle}>
          <Link
            component="button"
            onClick={() =>
              navigate(getRoutePath({ target: 'docsTermsOfService' }))
            }
            color="info.main"
          >
            {t('bannerText.termsOfService')}.
          </Link>
        </Typography>
      </Box>
    </ThemeProvider>
  );
};
