import { useState } from 'react';

import {
  DeleteOutlineOutlined,
  DriveFileMoveOutlined,
  Edit,
  GetApp,
  MoreVert,
  Print,
  Close,
} from '@mui/icons-material';
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import { getFileIconByExtension } from '../../../../utils/guards.utils';

export const TopBar = ({ type, fileName, fileExtension, url, onClose }) => {
  /* ================== Menu Logic ================== */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  /* ================== Menu Logic ================== */

  const printFile = async () => {
    const printWindow = window.open(url);
    if (printWindow) {
      printWindow.onload = function () {
        printWindow.print();
      };
    }
  };

  const downloadFile = async () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item display="flex" alignItems="center">
        <img
          width="initial"
          height={24}
          src={getFileIconByExtension(fileExtension)}
          alt="pdf icon"
        />
        <Typography variant="h6" color="white" ml={2}>
          {fileName}
        </Typography>
      </Grid>

      <Grid item>
        <>
          {type === 'pdf' || type === 'image' ? (
            <IconButton onClick={printFile}>
              <Print sx={{ color: 'white' }} />
            </IconButton>
          ) : null}
        </>

        <IconButton onClick={downloadFile}>
          <GetApp sx={{ color: 'white' }} />
        </IconButton>

        <IconButton onClick={onClose}>
          <Close sx={{ color: 'white' }} />
        </IconButton>

        {/*         <IconButton onClick={handleClick}>
          <MoreVert sx={{ color: 'white' }} />
        </IconButton> */}
      </Grid>
      {/* <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem>
          <IconButton disableRipple>
            <DriveFileMoveOutlined />
          </IconButton>
          <Typography variant="body2">Move to Folder</Typography>
        </MenuItem>
        <MenuItem>
          <IconButton disableRipple>
            <Edit />
          </IconButton>
          <Typography variant="body2">Rename VaultFile</Typography>
        </MenuItem>
        <MenuItem>
          <IconButton disableRipple>
            <DeleteOutlineOutlined />
          </IconButton>
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </Menu> */}
    </Grid>
  );
};
