import { SxProps, Theme } from '@mui/material';

export const styles: Record<string, SxProps<Theme>> = {
  containerSx: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '2rem 0',
  },
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 0',
    width: '100%',
  },
  titleText: {
    fontSize: '1.5rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    marginBottom: '1rem',
    color: 'text.primary',
  },
  actionButton: {
    margin: '1rem 0',
    textTransform: 'uppercase',
    borderRadius: '4px',
    padding: '0.5rem 2.5rem',
    backgroundColor: 'transparent',
    color: 'text.primary',
    border: '1px solid',
    borderColor: 'text.primary',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      boxShadow: 'none',
    },
  },
  textContainer: {
    width: '658px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1rem 0',
  },
  helperText: {
    fontSize: '14px',
    lineHeight: '143%',
    textAlign: 'center',
    color: 'text.primary',
  },
};
