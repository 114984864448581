import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Typography,
  Grid,
} from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { ConfigurableControlledInput } from 'app/components/Inputs/ControlledInput';
import { ConfigurableControlledCountry } from 'app/components/Inputs/ControlledInputCountry';
import { emailRules, lastNameRules, nameRules } from 'utils/validation.utils';
import { UserBasicInfo } from 'app/features/AddressBookSlice/types';
import { UserBasicInfoWithInvite } from 'app/components/Advisors/AddClient';
import style from './style';

interface ClientInformationFormProps {
  useFormInstance: UseFormReturn<UserBasicInfoWithInvite>;
  editUserInfo: boolean;
  clientInformation?: UserBasicInfo;
  inviteClient?: boolean;
  showInviteOption?: boolean;
  handleInviteChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function ClientInformationForm({
  useFormInstance,
  editUserInfo,
  clientInformation,
  inviteClient = true,
  showInviteOption = false,
  handleInviteChange = () => {},
}: ClientInformationFormProps) {
  const { t } = useTranslation();

  // Modify email rules if invite is "yes"
  const getEmailRules = () => ({
    ...emailRules,
    required: inviteClient,
  });

  // Override the gap to 24px to match design specs
  const containerStyles = {
    ...style.boxContainerStyles,
    gap: '24px',
  };

  return (
    <Box sx={containerStyles}>
      {/* First Name */}
      <ConfigurableControlledInput
        formInstance={useFormInstance}
        name="firstName"
        label={capitalize(t('signUp.placeholders.firstName'))}
        placeholder={t('signUp.placeholders.firstName')}
        disabled={editUserInfo}
        otherRules={nameRules}
        textFieldProps={{
          InputLabelProps: { shrink: true },
          helperText: t('thisFieldIsRequired'),
          sx: style.formField,
        }}
      />

      {/* Last Name */}
      <ConfigurableControlledInput
        formInstance={useFormInstance}
        name="lastName"
        label={capitalize(t('signUp.placeholders.lastName'))}
        placeholder={t('signUp.placeholders.lastName')}
        disabled={editUserInfo}
        otherRules={lastNameRules}
        textFieldProps={{
          InputLabelProps: { shrink: true },
          helperText: t('thisFieldIsRequired'),
          sx: style.formField,
        }}
      />

      {/* 
        WIP: Invite Option temporarily hidden until feature is fully implemented
        To be unhidden when https://myfo.atlassian.net/browse/MSP-2165 is completed
      */}
      {/* {showInviteOption && (
        <Box>
          <FormControl component="fieldset">
            <Typography variant="body1" sx={style.formLabel}>
              {t('advisors.signupLabel')}
            </Typography>
            <RadioGroup
              value={String(inviteClient)} // Convert boolean to string for Radio value
              onChange={handleInviteChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label={t('common.yes')}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={t('common.no')}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )} */}

      {/* Email */}
      <ConfigurableControlledInput
        formInstance={useFormInstance}
        name="email"
        label={capitalize(t('stakeholder.emailField'))}
        placeholder="Email"
        disabled={Boolean(clientInformation?.email) || editUserInfo}
        otherRules={getEmailRules()}
        textFieldProps={{
          InputLabelProps: { shrink: true },
          helperText: inviteClient
            ? t('thisFieldIsRequired')
            : t('advisor.optionalField'),
          sx: style.formField,
        }}
      />

      {/* Company */}
      <ConfigurableControlledInput
        formInstance={useFormInstance}
        name="company"
        label={capitalize(t('advisors.companyField'))}
        placeholder={t('advisors.companyField')}
        disabled={editUserInfo}
        otherRules={{ required: false }}
        textFieldProps={{
          InputLabelProps: { shrink: true },
          helperText: t('advisor.optionalField'),
          sx: style.formField,
        }}
      />

      {/* Country */}
      <ConfigurableControlledCountry
        useFormInstance={useFormInstance}
        name="country"
        placeholder={t('advisors.countryLabel')}
        disabled={editUserInfo}
        otherRules={{ required: false }}
        textFieldProps={{
          InputLabelProps: { shrink: true },
          helperText: t('advisor.optionalField'),
          sx: style.formField,
        }}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ConfigurableControlledInput
            formInstance={useFormInstance}
            name="countryCode"
            label={t('countryCode')}
            placeholder={t('countryCode')}
            disabled={editUserInfo}
            otherRules={{ required: false }}
            textFieldProps={{
              InputLabelProps: { shrink: true },
              helperText: t('advisor.optionalField'),
              sx: style.formField,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ConfigurableControlledInput
            formInstance={useFormInstance}
            name="phoneNumber"
            label={t('advisors.phoneLabel')}
            placeholder={t('advisors.phoneLabel')}
            disabled={editUserInfo}
            otherRules={{ required: false }}
            textFieldProps={{
              InputLabelProps: { shrink: true },
              helperText: t('advisor.optionalField'),
              sx: style.formField,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
