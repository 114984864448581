import React, { useState } from 'react';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ReactComponent as AlertCircleIcon } from 'app/assets/icons/alert-circle.svg';
import { useTranslation } from 'react-i18next';
import style from './style';
import { StakeholderCard } from '../StakeholderCard';
import { ContactSummary } from 'app/features/AddressBookSlice/types';
import FilterPopover from '../StakeholderFilterPopover';
import { InitialFilters } from 'constants/stakeholder';

interface Props {
  stakeholderList: ContactSummary[] | undefined;
  checked: typeof InitialFilters;
  setChecked: React.Dispatch<React.SetStateAction<typeof InitialFilters>>;
  manageOnClick?: (stakeholder: ContactSummary) => () => void;
  accessOnClick?: (stakeholder: ContactSummary) => () => void;
  deleteOnClick?: (stakeholder: ContactSummary) => () => void;
  onPageChange: (page: number) => void;
  currentPage: number;
}

// Column widths for [Name, Contact, Role, Permissions, Actions]
const columnWidths = [3, 3, 2, 3, 1];

// We'll render each header cell, injecting the filter icon only for "Role"
function renderEqGridItems(
  titleList: string[],
  widths: number[],
  handleFilterClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
) {
  return titleList.map((title, index) => (
    <Grid item key={index} xs={widths[index]}>
      {index === 2 ? (
        <Box display="flex" alignItems="center">
          <Typography variant="caption" color="text.secondary">
            {title}
          </Typography>
          <IconButton size="small" onClick={handleFilterClick}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={index === 4 ? style.actionColumnStyles : {}}
        >
          {title}
        </Typography>
      )}
    </Grid>
  ));
}

export function StakeHolderTable({
  stakeholderList,
  checked,
  setChecked,
  manageOnClick,
  accessOnClick,
  deleteOnClick,
  onPageChange,
  currentPage,
}: Props) {
  const { t } = useTranslation();

  const tableHeadersList = [
    t('name'),
    t('stakeholder.information'),
    t('role'),
    t('permissions'),
    t('stakeholder.actions'),
  ];

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleApplyFilters = newFilters => {
    setChecked(newFilters);
    // Reset to page 1 when filters are applied
    if (currentPage !== 0) {
      onPageChange(0);
    }
  };

  return (
    <Box sx={style.boxStyles}>
      <Box sx={style.topBorderStyles}></Box>

      <Grid container sx={style.headerStakeholderStyles}>
        {renderEqGridItems(tableHeadersList, columnWidths, handleFilterClick)}
      </Grid>

      <FilterPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        filterOptions={checked}
        setFilterOptions={handleApplyFilters}
      />

      <Box sx={style.containerSx}>
        {stakeholderList && stakeholderList.length > 0 ? (
          stakeholderList.map((stakeholder, index) => (
            <StakeholderCard
              key={index}
              {...stakeholder}
              {...(stakeholder as ContactSummary & {
                role?: string;
                permissions?: string[];
              })}
              manageOnClick={
                manageOnClick && !stakeholder.canImpersonate
                  ? manageOnClick(stakeholder)
                  : undefined
              }
              accessOnClick={
                accessOnClick && !stakeholder.canImpersonate
                  ? accessOnClick(stakeholder)
                  : undefined
              }
              deleteOnClick={deleteOnClick && deleteOnClick(stakeholder)}
            />
          ))
        ) : (
          <Box sx={style.noStakeholderStyles}>
            <AlertCircleIcon width={15} height={15} stroke="#9C968E" />
            <Typography variant="caption" color="secondary.main">
              {t('stakeholder.noStakeholder')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
