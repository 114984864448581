import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Stack,
  Button,
  Avatar,
} from '@mui/material';
import { capitalize } from '@mui/material/utils';
import {
  DeleteOutlined,
  EditOutlined,
  MoreVert,
  Person2Outlined,
  ChevronRight,
} from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { formatPhoneNumber, getInitials } from 'utils/formats';
import style from './style';
import { ContactSummary } from 'app/features/AddressBookSlice/types';

//TODO should have a prop called contact not extend props with ContactSummary
type Props = {
  editOnClick?: () => void;
  deleteOnClick?: () => void;
  viewConsolidation?: () => void;
  viewOnClick?: () => void;
} & Omit<ContactSummary, 'accessList' | 'ownershipList'>;

export function AdvisorCard({
  targetUserId,
  connectionManagerMeta,
  viewOnClick,
  viewConsolidation,
  deleteOnClick,
  editOnClick,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container key={targetUserId} sx={style.cardStyles}>
      {/* User Badge */}
      <Grid item xs={1} sx={style.avatarContainer}>
        <Avatar sx={style.avatarStyles}>
          {getInitials(
            connectionManagerMeta?.firstName,
            connectionManagerMeta?.lastName,
          )}
        </Avatar>
      </Grid>

      {/* Name */}
      <Grid item xs={3} sx={style.nameContainer}>
        <Typography
          variant="body1"
          color="black.100"
          textTransform="capitalize"
        >
          {connectionManagerMeta?.firstName} {connectionManagerMeta?.lastName}
        </Typography>
      </Grid>

      {/* Contact details column */}
      <Grid item xs={5} sx={style.contactContainer}>
        {connectionManagerMeta?.email && (
          <Box>
            <Typography variant="caption" color="text.secondary">
              {connectionManagerMeta?.email}
            </Typography>
          </Box>
        )}
        {connectionManagerMeta?.phoneNumber && (
          <Box>
            <Typography variant="caption" color="text.disabled">
              {formatPhoneNumber(
                connectionManagerMeta?.phoneNumber,
                connectionManagerMeta.countryCode,
              )}
            </Typography>
          </Box>
        )}
      </Grid>

      {/* View button column */}
      <Grid item xs={2} sx={style.buttonContainer}>
        {viewOnClick && (
          <Button
            variant="outlined"
            size="small"
            endIcon={<ChevronRight />}
            onClick={viewOnClick}
            sx={style.viewButtonStyles}
          >
            {t('view').toUpperCase()}
          </Button>
        )}
      </Grid>

      {/* Options column */}
      <Grid item xs={1} sx={style.boxButtons}>
        <IconButton onClick={handleClick} sx={style.iconButtonStyles}>
          <MoreVert />
        </IconButton>

        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          {viewConsolidation && (
            <MenuItem onClick={viewConsolidation} sx={style.menuItemStyles}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Person2Outlined sx={style.iconStyles} />
                <Typography variant="body2">
                  {capitalize(t('advisors.button.view'))}
                </Typography>
              </Stack>
            </MenuItem>
          )}
          {viewOnClick && (
            <MenuItem onClick={viewOnClick} sx={style.menuItemStyles}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <VisibilityOutlinedIcon sx={style.iconStyles} />
                <Typography variant="body2">
                  {capitalize(t('advisors.button.viewClient.details'))}
                </Typography>
              </Stack>
            </MenuItem>
          )}
          {editOnClick && (
            <MenuItem onClick={editOnClick} sx={style.menuItemStyles}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <EditOutlined sx={style.iconStyles} />
                <Typography variant="body2">
                  {capitalize(t('advisors.button.edit'))}
                </Typography>
              </Stack>
            </MenuItem>
          )}
          {deleteOnClick && (
            <MenuItem onClick={deleteOnClick} sx={style.menuItemStyles}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <DeleteOutlined sx={style.iconStyles} />
                <Typography variant="body2">
                  {capitalize(t('advisors.button.delete'))}
                </Typography>
              </Stack>
            </MenuItem>
          )}
        </Menu>
      </Grid>
    </Grid>
  );
}
