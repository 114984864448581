/* eslint-disable */
import React, {
  useMemo,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Box, Typography, IconButton, LinearProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Close } from '@mui/icons-material';

import { BaseContext } from 'app/features/AssetLiabilitySlice/types';
import {
  reactDropZoneStyle,
  dropzoneTextContainer,
  fileDescriptionContainer,
  iconContainer,
  VisuallyHiddenInput,
  filesContainer,
  filesIconContainer,
  fileDescription,
} from './style';
import { bytesToSize } from './utils';
import { statusColors } from './constants';
import { UploadFileType } from 'app/pages/vault/utils/fileUpload.utils';

export interface PropsForm {
  formInstance: UseFormReturn<BaseContext>;
  name: string;
  onRead?: (data: any) => void;
  onReadCsv?: (csvString, data) => void;
  fileType?: string;
  disclaimer?: string;
  accept?: any;
  setDataPerFile?: Dispatch<SetStateAction<UploadFileType[]>>;
  ContentDropzone: () => JSX.Element;
  validateHeaders?: (csvString?: string | ArrayBuffer | null) => void;
  handleDelete?: (item) => void;
}

export const UploadFile: React.FC<PropsForm> = ({
  name,
  formInstance: { register, unregister, watch, setValue },
  onRead,
  fileType = 'CSV',
  accept = '',
  setDataPerFile,
  onReadCsv,
  ContentDropzone,
  disclaimer,
  handleDelete,
  validateHeaders,
}) => {
  const { t } = useTranslation();

  const progress = 100;
  const [status, setStatus] = useState('LOADING');
  const uploadedFiles = watch('uploadedFiles', []);

  useEffect(() => {
    return () => {
      setDataPerFile?.([]);
    };
  }, [setDataPerFile]);

  const readFileAsync = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
      reader.readAsText(file);
    });
  };

  const onDrop = async (newFiles: File[]) => {
    try {
      const uniqueNewFiles = newFiles.filter(
        (newFile: File) =>
          !uploadedFiles.some(
            (existingFile: File) => existingFile.name === newFile.name,
          ),
      );

      if (uniqueNewFiles.length === 0) return;

      const processedFiles = await Promise.all(
        uniqueNewFiles.map(async file => {
          try {
            const result = await readFileAsync(file);

            if (validateHeaders?.(result)) return;

            return {
              name: file.name,
              size: file.size,
              type: file.type,
              result,
              file,
            };
          } catch (error) {
            return null;
          }
        }),
      );
      const validFiles = processedFiles.filter(Boolean) as UploadFileType[];

      if (!validFiles.length) return;

      setValue('uploadedFiles', [...uploadedFiles, ...validFiles]);

      setDataPerFile?.(prev => [...prev, ...validFiles]);
      validFiles.forEach(data => {
        onRead?.(data);
        onReadCsv?.(data.result, data);
      });
      setStatus('COMPLETE');
    } catch (error) {
      setStatus('FAILED');
    }
  };

  const removeFile = (fileToRemove: File) => {
    const updatedFiles = uploadedFiles.filter(
      (file: File) => file.name !== fileToRemove.name,
    );
    setValue('uploadedFiles', updatedFiles);
    setDataPerFile?.(prev =>
      prev.filter(file => file.name !== fileToRemove.name),
    );
    handleDelete?.(fileToRemove);
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      accept,
      multiple: true,
    });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const style = useMemo(
    () => ({
      ...reactDropZoneStyle,
      ...(isDragActive ? { backgroundColor: '#e8f5e9' } : {}),
    }),
    [isDragActive],
  );

  return (
    <>
      <Box {...getRootProps({ style })}>
        <VisuallyHiddenInput type="file" {...getInputProps()} />
        <Box sx={iconContainer}>
          <UploadFileIcon color="primary" fontSize="large" />
        </Box>

        <Box sx={dropzoneTextContainer}>
          <ContentDropzone />
        </Box>
        <Typography variant="body2" color="secondary.main">
          {disclaimer && disclaimer.trim() !== ''
            ? disclaimer
            : capitalize(t('uploadFile.fileAccepted', { fileType }))}
        </Typography>
        <>
          {fileRejections.length > 0 && (
            <Typography
              variant="bodyAccent"
              color="error.main"
              fontWeight="bold"
              mt={2}
            >
              {t('uploadFile.fileNotSupported')}
            </Typography>
          )}
        </>
      </Box>
      {uploadedFiles.map((file: File) => (
        <Box key={file.name} sx={filesContainer}>
          <>
            <Box sx={fileDescriptionContainer}>
              <Box
                sx={{
                  ...filesIconContainer,
                  backgroundColor: statusColors[status].uploadBox,
                }}
              >
                <UploadFileIcon
                  color={statusColors[status].icon}
                  fontSize="medium"
                />
              </Box>
              <Box sx={fileDescription}>
                <Typography variant="body2">{file.name}</Typography>
                <Typography
                  variant="body2"
                  color={statusColors[status].text}
                  mb={2}
                >
                  {bytesToSize(file.size)} • {status}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  color={statusColors[status].progress}
                  value={progress}
                />
              </Box>
            </Box>

            <IconButton onClick={() => removeFile(file)}>
              <Close color={statusColors[status].icon} />
            </IconButton>
          </>
        </Box>
      ))}
    </>
  );
};
