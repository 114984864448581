import { palette } from '../palette';

export const light = {
  ...palette,
  primary: {
    main: '#2C3C3D',
    dark: '#1F2A2B',
    medium: '#384A4C',
    light: '#45595B',
    contrast: '#FFFFFF',
    back: '#1A2425',
    '4p': '#2C3C3D0A',
    '8p': '#2C3C3D14',
    '12p': '#2C3C3D1F',
    '30p': '#2C3C3D4D',
    '50p': '#2C3C3D80',
    '160p': '#E8EBEC',
  },
  secondary: {
    main: '#9C968E',
    dark: '#78746D',
    light: '#B4B0AA',
    contrast: '#FFFFFF',
    '4p': '#9C968E0A',
    '8p': '#9C968E14',
    '12p': '#9C968E1F',
    '30p': '#9C968E4D',
    '50p': '#9C968E80',
    '160p': '#D54340',
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    contrast: '#FFFFFF',
    contrastText: '#303D45',
    '4p': '#D32F2F0A',
    '12p': '#D32F2F1F',
    '30p': '#D32F2F4D',
    '50p': '#D32F2F80',
    '90p': '#FFDAD6',
    '160p': '#541313',
    '190p': '#FBEAEA',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrast: '#FFFFFF',
    '4p': '#ED6C020A',
    '12p': '#ED6C021F',
    '30p': '#ED6C024D',
    '50p': '#ED6C0280',
    '160p': '#5F2B01',
    '190p': '#FDF0E6',
  },
  info: {
    main: '#4B7961',
    dark: '#345548',
    light: '#6B9A80',
    contrast: '#FFFFFF',
    back: '#4B796112',
    green: '#C8E6C9',
    '4p': '#4B79610A',
    '12p': '#4B79611F',
    '30p': '#4B79614D',
    '50p': '#4B796180',
    '160p': '#E8F2EC',
    '190p': '#F0F7F2',
  },
  success: {
    main: '#2E7D32',
    dark: '#1B5E20',
    light: '#4CAF50',
    contrast: '#FFFFFF',
    '4p': '#2E7D320A',
    '12p': '#2E7D321F',
    '30p': '#2E7D324D',
    '50p': '#2E7D3280',
    '160p': '#123234',
    '190p': '#EAF2EA',
  },
  action: {
    active: '#2C3C3D80',
    hover: '#2C3C3D14',
    selected: '#2C3C3D1F',
    disabled: '#2C3C3D61',
    disabledBackground: '#2C3C3D30',
    focus: '#2C3C3D1F',
  },
  other: {
    divider: '#1018201F',
    outlineBorder: '#1018203B',
    inputLine: '#0000006B',
    backdropOverlay: '#00000080',
    ratingActive: '#FFB400',
    snackbarBackground: '#323232',
    sidebarInternal: '#F8FBFC',
    sidebar: '#EEEEEE',
  },
  addition: {
    infoBackgroundColor: '#E8F2EC',
    accordionBackgroundColor: '#F8F8F9',
    lightGreyLabelColor: '#D3D3D3',
    textDarkGrey: '#A9A9A9',
    controlButtonBackgroundColor: '#101820',
    entityNodePercentageColor: '#22372B',
    entityPersonalWrapperBackgroundColor: '#2C3C3D',
    entityMapHandleBackgorund: '#555555',
    inputDateError: '#BA1A1A',
    assetHistoryEssential: '#E8EBEC',
    taskManagerDarkGreyColor: '#707479',
    tableListGreyColor: '#CFD1D2',
    iconWarningBackgroundColor: '#FAFAF9',
    taskManagerStatusToDoBackground: '#ECEFF1',
    taskManagerStatusDoingBackground: '#EDE7F6',
    taskManagerStatusDoingBorder: '#7E57C2',
    taskManagerStatusDoneBackground: '#E1F5FE',
    taskManagerStatusDoneBorder: '#00000042',
    vaultProcessingBg: '#e8e8e8',
  },
};
