import {
  AccountingDataReports,
  AssetByType,
  BaseContext,
  HoldingReadingInterface,
  Item,
} from '../AssetLiabilitySlice/types';
import { ConnectedAccountStatus } from '../ConnectedAccountsSlice/types';

export enum GroupTypes {
  COMPANY = 'COMPANY',
  TRUST = 'TRUST',
  FAMILY_FOUNDATION = 'FAMILY_FOUNDATION',
  PERSONAL = 'PERSONAL',
  CONSOLIDATED = 'CONSOLIDATED',
}

export enum AccountTypes {
  investment = 'investment',
  banking = 'banking',
}

/* --- STATE --- */
export type Actor = {
  accessLevel: string;
  actorId: string;
  address: string;
  category: string;
  createdBy: string;
  createdOn: string;
  email: string;
  firstName: string;
  lastName: string;
  parentId: string;
  phoneNumber: string;
  status: string;
  subCategory: string;
  userId?: string;
};

export type Integration = {
  groupId: string;
  createdAt: string;
  institutionId: string;
  integrationHash: string;
  providerId: string;
  providerName?: string;
  provider?: string;
  status: ConnectedAccountStatus;
  taskId: string;
  institution?: string;
  productType?: AccountTypes;
  updatedAt: string;
};

export type GroupData = {
  _id: string;
  groupAdmin: string;
  groupStakePercentage?: string | number; // TODO: Careful with this union type
  groupAdminName?: string;
  groupName: string;
  groupNameDisplay: string;
  groupType: GroupTypes;
  items: Item[];
  integrations: Integration[];
  stakeholders: Stakeholder[];
  baseContext?: Record<string, any>;
  groupOwnership?: string;
  parentOwner?: GroupData;
};

export type InviteUserData = {
  groupId: string;
  targetEmail?: string;
  targetPhone?: string;
};

export type GroupFormData = {
  groupName: string;
  groupType: string;
  groupStakePercentage: number | string;
  groupId?: string;
};
export type GroupTypesData = {
  typeId: string;
  name: string;
};

export enum StakeholderStatus {
  LOCAL = 'LOCAL',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export type Stakeholder = {
  _id: string;
  percentageOwned?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  entity?: string;
  actorId?: string;
  phoneNumber?: string;
  accessLevel?: string;
  role?: string;
  company?: string;
  title?: string;
  address?: string;
  categories: string[];
  subCategories: string[];
  userId?: string;
  parentId?: string;
  createdBy?: string;
  createdOn?: string;
  share?: string;
  status?: StakeholderStatus;
  itemAccess?: { _id: string; accessLevel: string }[];
};

type BaseItem = {
  sourceId: string;
  name: string;
  accountNumber: string;
  description: string;
  currency: string;
  balance: number;
  type: string;
  isValue: boolean;
  currentValuation?: boolean;
  cost?: boolean;
};

export type ConnectedItem = {
  baseContext: BaseItem;
  itemType: string;
  status: string;
  updatedOn: string;
  createdOn: string;
  groupId: string;
  createdBy: string;
};

export interface ConnectionProps {
  connections: ConnectedItem[];
  groupId: string;
  companyId: string | null;
}

export type GroupCalculations = {
  availableCash: number;
  availableLeverage: number;
  availableLiquidity: number;
  annualReturn: number;
  totalCommitment: number;
  remainingCommitment: number;
  quarterlyReturn: number;
  totalNetWorth: number;
  annualNetWorth: number;
  quarterlyNetWorth: number;
  groupPercentage: number;
  commitmentTotal: number;
  grantTotal: number;
  donationTotal: number;
  _id: string;
};

export interface GroupState {
  // Base
  groups: GroupData[];
  groupIds: string[];
  currentGroupId?: string;
  loaded: boolean;
  loadedCalculations: boolean;
  assetsAverages: {
    all: HoldingReadingInterface[];
    current: HoldingReadingInterface[];
  };
  // Group codat connection
  accountingDataGroup: AccountingDataReports;
  getAccountingDataGroupError: Error | null;
  isFetchingAccountingDataGroup: boolean;
  isFetchingCompleteConnection: boolean;
  getCompleteConnectionError: Error | null;
  completeConnection: any;
  isFetchingConnectionsData: boolean;
  getConnectionsDataError: Error | null;
  connectionsData: ConnectedItem[];
  // Group endpoint operations
  isFetching: boolean;
  isSuccess: boolean;
  isCreateSuccess: boolean;
  isCreatingGroup: boolean;
  serviceError: any | Error | undefined | null;
  formData?: GroupFormData;

  // Group Types
  groupTypes: GroupTypesData[] | null;
  groupTypesIsFetching: boolean;
  groupTypesIsSuccess: boolean;
  groupTypesError: Error | null;
  groupCalculations: Record<string, GroupCalculations>;

  // Stakeholders
  stakeholders: Array<Stakeholder> | null;
  stakeholdersGroupId: StakeholdersGroupId | null;
  stakeholdersIsFetching: boolean;
  stakeholdersIsSuccess: boolean;
  stakeholdersError: Error | null;
  stakeholderData: Stakeholder | null;
  addStakeholderIsFetching: boolean;
  addStakeholderIsSuccess: boolean;
  addStakeholderError: Error | null;
  editStakeholderIsFetching: boolean;
  editStakeholderIsSuccess: boolean;
  editStakeholderError: Error | null;
  updateStakeholderIsFetching: boolean;
  updateStakeholderIsSuccess: boolean;
  updateStakeholderError: Error | null;
  deleteStakeholderIsFetching: boolean;
  deleteStakeholderIsSuccess: boolean;
  deleteStakeholderError: Error | null;
  stakeholderAssets: AssetByType | null;
  stakeholderAssetsIsFetching: boolean;
  stakeholderAssetsIsSuccess: boolean;
  stakeholderAssetsError: Error | null;
  stakeholderLiabilities: AssetByType | null;
  stakeholderLiabilitiesIsFetching: boolean;
  stakeholderLiabilitiesIsSuccess: boolean;
  stakeholderLiabilitiesError: Error | null;
}

export type TableData = Array<{
  name: string;
  id: string;
  accounts: Array<{
    accountNumber: string;
    type: string;
  }>;
}>;

export type StakeholdersGroupId = Array<Stakeholder & { groupId: string }>;

export type SharedItem = {
  type: 'Entity' | 'Asset/Liability' | 'MyFO Account';
  name: string;
  ownerName: string;
  percentageOwned: number;
  ownerEmail: string;
  alternativeId: string;
  sharedDate: string;
  status: StakeholderStatus;
  accessLevel: string;
  accessEntities: { entityId: string; stakeholderId: string }[];
  groupId: string;
};
