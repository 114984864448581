import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { assetLiabilitySaga } from './saga';
import {
  Asset,
  AssetInput,
  AssetLiabilityState,
  Liability,
  LiabilityInput,
  viewAsset,
  UpdateAsset,
  CurrentHoldingInterface,
  UpdateLiability,
  UpdateItem,
  AccountingDataReports,
  UpdatePositions,
} from './types';
import { createHoldingReadings } from './functionality';

export const initialState: AssetLiabilityState = {
  assets: undefined,
  liabilities: undefined,
  isFetchingAssets: false,
  isFetchingLiabilities: false,
  getAssetsError: null,
  getLiabilitiesError: null,
  createAssetError: null,
  isCreatingAsset: false,
  isEditingAsset: false,
  editAssetError: null,
  assetCreateStatus: null,
  assetEditStatus: undefined,
  isEditingItem: false,
  itemEditStatus: undefined,
  editItemError: undefined,
  createdAsset: null,
  createLiabilityError: null,
  isCreatingLiability: false,
  isEditingLiability: false,
  editLiabilityError: null,
  liabilityCreateStatus: null,
  liabilityEditStatus: undefined,
  createdLiability: null,
  isFetchingAssetsLiabilityCalculation: false,
  getAssetsLiabilityError: null,
  getAssetEditError: null,
  asset: null,
  isDeletingAsset: false,
  deleteAssetError: null,
  getLiabilityEditError: null,
  liability: null,
  isDeletingLiability: false,
  deleteLiabilityError: null,
  isFetchingLiabilityView: false,
  isFetchingAssetView: false,
  refreshDashboard: false,
  assestPercentageByClass: null,
  isAssociatingItem: false,
  deleteItemStatus: undefined,
  isFetchingAccountingData: false,
  getAccountingDataError: null,
  accountingData: {},
  isFetchingPositions: false,
  editPositionsError: null,
  editPositions: null,
};

const slice = createSlice({
  name: 'assetLiability',
  initialState,
  reducers: {
    getAssets(state, action: PayloadAction<string>) {
      state.isFetchingAssets = true;
      state.getAssetsError = null;
    },
    getAssetsSuccess(state, action: PayloadAction<Asset[]>) {
      state.isFetchingAssets = false;
      state.assets = action.payload;
      state.getAssetsError = null;
    },
    getAssetsError(
      state,
      action: PayloadAction<AssetLiabilityState['getAssetsError']>,
    ) {
      state.isFetchingAssets = false;
      state.getAssetsError = action.payload;
    },
    createAsset(state, action: PayloadAction<AssetInput>) {
      state.isCreatingAsset = true;
      state.createAssetError = null;
    },
    createAssetSuccess(state, action: PayloadAction<Asset>) {
      state.isCreatingAsset = false;
      state.createAssetError = null;
      state.assetCreateStatus = 'success';
      state.createdAsset = action.payload;
    },
    setAssociteItemModal(state, action: PayloadAction<boolean>) {
      state.isAssociatingItem = action.payload;
    },
    createAssetError(
      state,
      action: PayloadAction<AssetLiabilityState['createAssetError']>,
    ) {
      state.createAssetError = action.payload;
      state.isCreatingAsset = false;
      state.assetCreateStatus = 'error';
      state.createdAsset = null;
    },
    cleanupCreateAsset(state) {
      state.isCreatingAsset = false;
      state.createAssetError = null;
      state.assetCreateStatus = null;
      state.assetEditStatus = undefined;
      state.createdAsset = null;
    },
    editAsset(state, action: PayloadAction<UpdateAsset | UpdateLiability>) {
      state.isEditingAsset = true;
      state.editAssetError = null;
    },
    editAssetSuccess(state, action: PayloadAction<{ shouldRefresh: boolean }>) {
      state.isEditingAsset = false;
      state.editAssetError = null;
      state.assetEditStatus = 'success';
    },
    editAssetError(
      state,
      action: PayloadAction<AssetLiabilityState['editAssetError']>,
    ) {
      state.editAssetError = action.payload;
      state.assetEditStatus = 'error';
      state.isEditingAsset = false;
    },
    editPositions(state, action: PayloadAction<UpdatePositions>) {
      state.isFetchingPositions = true;
      state.editPositionsError = null;
    },
    editPositionsSuccess(state, action: PayloadAction<Asset>) {
      state.isFetchingPositions = false;
      state.editPositionsError = null;
      state.asset = action.payload;
    },
    editPositionsError(
      state,
      action: PayloadAction<AssetLiabilityState['editPositionsError']>,
    ) {
      state.editPositionsError = action.payload;
      state.isFetchingPositions = false;
    },
    editItem(state, action: PayloadAction<UpdateItem>) {
      state.isEditingItem = true;
      state.editItemError = undefined;
    },
    editItemSuccess(state) {
      state.isEditingItem = false;
      state.editItemError = undefined;
      state.itemEditStatus = 'success';
    },
    editItemError(
      state,
      action: PayloadAction<AssetLiabilityState['editItemError']>,
    ) {
      state.editItemError = action.payload;
      state.itemEditStatus = 'error';
      state.isEditingItem = false;
    },
    cleanupEditItem(state) {
      state.isEditingItem = false;
      state.itemEditStatus = undefined;
      state.editItemError = undefined;
    },
    createLiability(state, action: PayloadAction<LiabilityInput>) {
      state.isCreatingLiability = true;
      state.createLiabilityError = null;
    },
    createLiabilitySuccess(state, action: PayloadAction<Liability>) {
      state.isCreatingLiability = false;
      state.createLiabilityError = null;
      state.liabilityCreateStatus = 'success';
      state.createdLiability = action.payload;
    },
    createLiabilityError(
      state,
      action: PayloadAction<AssetLiabilityState['createLiabilityError']>,
    ) {
      state.createLiabilityError = action.payload;
      state.isCreatingLiability = false;
      state.liabilityCreateStatus = 'error';
      state.createdLiability = null;
    },
    cleanupCreateLiability(state) {
      state.isCreatingLiability = false;
      state.createLiabilityError = null;
      state.liabilityCreateStatus = null;
      state.liabilityEditStatus = undefined;
      state.createdLiability = null;
    },
    editLiability(state, action: PayloadAction<UpdateLiability>) {
      state.isEditingLiability = true;
      state.editLiabilityError = null;
    },
    editLiabilitySuccess(
      state,
      action: PayloadAction<{ shouldRefresh: boolean }>,
    ) {
      state.isEditingLiability = false;
      state.liabilityEditStatus = 'success';
      state.editLiabilityError = null;
    },
    editLiabilityError(
      state,
      action: PayloadAction<AssetLiabilityState['editLiabilityError']>,
    ) {
      state.editLiabilityError = action.payload;
      state.liabilityEditStatus = 'error';
      state.isEditingLiability = false;
    },
    getLiabilities(state, action: PayloadAction<string>) {
      state.isFetchingLiabilities = true;
      state.getLiabilitiesError = null;
    },
    getLiabilitiesSuccess(state, action: PayloadAction<Liability[]>) {
      state.isFetchingLiabilities = false;
      state.liabilities = action.payload;
      state.getLiabilitiesError = null;
    },
    getLiabilitiesError(
      state,
      action: PayloadAction<AssetLiabilityState['getLiabilitiesError']>,
    ) {
      state.isFetchingLiabilities = false;
      state.getLiabilitiesError = action.payload;
    },
    cleanAssetView(state) {
      state.asset = null;
    },
    getAssetView(state, action: PayloadAction<viewAsset>) {
      state.getAssetEditError = null;
      state.isFetchingAssetView = true;
    },
    getAssetViewSuccess(state, action: PayloadAction<Asset>) {
      state.asset = action.payload;
      state.getAssetEditError = null;
      state.isFetchingAssetView = false;
    },
    getAssetViewError(
      state,
      action: PayloadAction<AssetLiabilityState['getAssetEditError']>,
    ) {
      state.getAssetEditError = action.payload;
      state.isFetchingAssetView = false;
    },
    getAccountingData(state, action: PayloadAction<viewAsset>) {
      state.getAccountingDataError = null;
      state.isFetchingAccountingData = true;
    },
    getAccountingDataSuccess(
      state,
      action: PayloadAction<AccountingDataReports>,
    ) {
      state.accountingData = action.payload;
      state.getAccountingDataError = null;
      state.isFetchingAccountingData = false;
    },
    getAccountDataError(
      state,
      action: PayloadAction<AssetLiabilityState['getAccountingDataError']>,
    ) {
      state.accountingData = {};
      state.getAccountingDataError = action.payload;
      state.isFetchingAccountingData = false;
    },

    deleteAsset(state, action: PayloadAction<viewAsset>) {
      state.isDeletingAsset = true;
      state.deleteItemStatus = undefined;
      state.deleteAssetError = null;
    },
    deleteAssetSuccess(state, action: PayloadAction<{ _id: string }>) {
      state.isDeletingAsset = false;
      state.deleteItemStatus = 'success';
      state.deleteAssetError = null;
      // Remove the asset with the given id from the assets array:
      if (state.assets) {
        state.assets = state.assets.filter(
          asset => asset._id !== action.payload._id,
        );
      }
    },
    deleteAssetError(
      state,
      action: PayloadAction<AssetLiabilityState['deleteAssetError']>,
    ) {
      state.deleteAssetError = action.payload;
      state.deleteItemStatus = 'error';
      state.isDeletingAsset = false;
    },
    cleanDeleteItemState(state) {
      state.isDeletingAsset = false;
      state.deleteItemStatus = undefined;
      state.deleteAssetError = null;
    },
    cleanUpItemOperation(state) {
      state.liabilities = undefined;
      state.assets = undefined;
    },
    cleanLiabilityView(state) {
      state.liability = null;
    },
    getLiabilityView(state, action: PayloadAction<viewAsset>) {
      state.isFetchingLiabilityView = true;
      state.getLiabilityEditError = null;
    },
    getLiabilityViewSuccess(state, action: PayloadAction<Liability>) {
      state.isFetchingLiabilityView = false;
      state.liability = action.payload;
      state.getLiabilityEditError = null;
    },
    getLiabilityViewError(
      state,
      action: PayloadAction<AssetLiabilityState['getLiabilityEditError']>,
    ) {
      state.getLiabilityEditError = action.payload;
      state.isFetchingLiabilityView = false;
    },

    deleteLiability(state, action: PayloadAction<viewAsset>) {
      state.isDeletingLiability = true;
      state.deleteLiabilityError = null;
    },
    deleteLiabilitySuccess(state, action: PayloadAction<{ _id: string }>) {
      state.isDeletingLiability = false;
      state.deleteLiabilityError = null;
      if (state.liabilities) {
        state.liabilities = state.liabilities.filter(
          liability => liability._id !== action.payload._id,
        );
      }
    },
    deleteLiabilityError(
      state,
      action: PayloadAction<AssetLiabilityState['deleteLiabilityError']>,
    ) {
      state.deleteLiabilityError = action.payload;
      state.isDeletingLiability = false;
    },
    setCurrentHoldingDataInPercentage(
      state,
      action: PayloadAction<CurrentHoldingInterface>,
    ) {
      state.assestPercentageByClass = createHoldingReadings(action.payload);
    },
  },
});

export const { actions: assetLiabilityActions, reducer } = slice;

export const useAssetLiabilitySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: assetLiabilitySaga });
  return { actions: slice.actions };
};
