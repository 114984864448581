export default {
  cardStyles: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    borderBottom: '1px solid',
    borderColor: 'other.divider',
    minHeight: '56px',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemStyles: {
    paddingLeft: '16px',
    paddingRight: '16px',
    minWidth: '200px',
  },
  iconStyles: {
    color: 'action.active',
    fontSize: 20,
  },
  iconButtonStyles: {
    color: 'text.secondary',
  },
  viewButtonStyles: {
    textTransform: 'uppercase',
    borderRadius: '4px',
    padding: '4px 10px',
    fontSize: '14px',
    fontWeight: 500,
    minWidth: '74px',
    height: '30px',
    color: 'text.secondary',
    borderColor: 'secondary.main',
    backgroundColor: 'primary.contrast',
    '&:hover': {
      backgroundColor: 'secondary.60p',
      borderColor: 'secondary.main',
      boxShadow: '0 0 0 1px rgba(0,0,0,0.05)',
      color: 'primary.contrast',
    },
  },
  avatarStyles: {
    width: 32,
    height: 32,
    backgroundColor: 'secondary.light',
    color: 'text.secondary',
    fontSize: '14px',
  },
};
