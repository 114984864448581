import React from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { InputLabel, Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../Input';
import { muiTheme } from 'styles/muiTheme';
import { getInputErrors } from '../utils';

/**
 * Base props interface for all controlled input components
 * Contains common functionality shared by all input types
 */
interface ControlledInputBaseProps extends Omit<InputProps, 'name'> {
  name: string;
  label: string;
  innerLabel?: boolean;
  formInstance: UseFormReturn<any>;
  otherRules?: RegisterOptions;
  textFieldProps?: Partial<InputProps>;
  defaultRequired?: boolean;
  helperTextIcon?: ((props: any) => React.ReactElement | null) | undefined;
}

const ControlledInputBase = (props: ControlledInputBaseProps) => {
  const { t } = useTranslation();
  const {
    name,
    label,
    disabled,
    innerLabel = true,
    otherRules,
    formInstance,
    placeholder,
    textFieldProps = {},
    defaultRequired = false,
    helperTextIcon,
    currency,
    ...inputValueProps
  } = props;

  const { register, watch, getFieldState } = formInstance;
  const value = watch(name, inputValueProps.InputProps?.value || '');

  const { hasError, errorMessage } = getInputErrors({
    name,
    disabled,
    getFieldState,
  });

  const validationRules = {
    ...(disabled ? {} : otherRules || {}),
    required:
      (otherRules?.required || defaultRequired) && !disabled
        ? {
            value: true,
            message: capitalize(t('thisFieldIsRequired')),
          }
        : false,
  };

  return (
    <>
      {!innerLabel && (
        <InputLabel htmlFor={name} sx={muiTheme.variants.inputLabel}>
          <Typography variant="body1" color="text.primary">
            {label}
          </Typography>
        </InputLabel>
      )}
      <Input
        {...inputValueProps}
        {...textFieldProps}
        id={name}
        label={innerLabel ? label : undefined}
        placeholder={placeholder || label}
        disabled={disabled}
        isError={hasError}
        error={hasError}
        helperText={errorMessage || textFieldProps.helperText}
        HelperTextIcon={helperTextIcon}
        currency={currency}
        {...register(name, validationRules)}
        value={value}
      />
    </>
  );
};

export interface ControlledInputProps extends InputProps {
  name: string;
  label: string;
  innerLabel?: boolean;
  formInstance: UseFormReturn<any>;
  otherRules?: RegisterOptions;
}

export const ControlledInput = (props: ControlledInputProps) => {
  return (
    <ControlledInputBase
      {...props}
      otherRules={{
        required: true,
        ...props.otherRules,
      }}
    />
  );
};

/**
 * Extended props interface for configurable inputs
 * Adds additional configuration options beyond the base props
 * @see ControlledInputBaseProps for common properties inherited by this interface
 * @deprecated - Will be consolidated in future with standardized input interfaces (https://myfo.atlassian.net/browse/MSP-2631)
 */
export interface ConfigurableControlledInputProps
  extends Omit<InputProps, 'name'> {
  name: string;
  label: string;
  innerLabel?: boolean;
  formInstance: UseFormReturn<any>;
  otherRules?: RegisterOptions;
  textFieldProps?: Partial<InputProps>;
}

export const ConfigurableControlledInput = (
  props: ConfigurableControlledInputProps,
) => {
  return <ControlledInputBase {...props} />;
};
