export default {
  userHomeStyles: {
    padding: '16px 1em 0 0',
    marginTop: 2.5,
    marginBottom: 10,
  },
  userRightPanelStyles: {
    minWidth: '200px',
  },

  mainContentGridStyles: isStakeholderRoute => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: isStakeholderRoute ? '-240px' : '0',
    width: isStakeholderRoute ? 'calc(100% + 240px)' : '100%',
  }),
};
