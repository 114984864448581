import { SxProps, Theme } from '@mui/material';
import { muiTheme } from 'styles/muiTheme';

const clientContextStyle: Record<string, SxProps<Theme>> = {
  appBar: {
    backgroundColor: muiTheme.palette.text.primary,
    color: muiTheme.palette.primary.contrast,
    position: 'fixed',
    top: '48px',
    left: 0,
    width: '100%',
    boxShadow: 'none',
    borderBottom: `1px solid ${muiTheme.palette.action.focus}`,
    height: '45px',
    zIndex: 899,
  },
  toolbar: {
    minHeight: '50px !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0',
    height: '100%',
    [muiTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '8px 0',
      gap: '8px',
    },
  },
  bannerText: {
    ...muiTheme.typography.body2,
    color: muiTheme.palette.primary.contrast,
    marginLeft: '329px', // Exact margin from Figma
    [muiTheme.breakpoints.down('sm')]: {
      fontSize: '12px',
      textAlign: 'center',
      marginLeft: 0,
    },
  },
  backButton: {
    ...muiTheme.typography.buttonSmall,
    color: muiTheme.palette.text.primary,
    backgroundColor: muiTheme.palette.primary.contrast,
    borderColor: muiTheme.palette.primary.contrast,
    fontWeight: 'bold',
    height: '31px',
    width: '199px',
    borderRadius: '4px',
    padding: '0 8px',
    marginRight: '78px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderColor: '#FFFFFF',
      backgroundColor: '#F5F5F5',
    },
    [muiTheme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    textTransform: 'none',
  },
  backButtonIcon: {
    marginRight: '8px',
    fontSize: '20px',
  },
};

export default clientContextStyle;
