import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Card, Typography, Divider } from '@mui/material';
import { ImpersonateUserConnection } from 'app/features/AddressBookSlice/types';
import { CustomTablePagination } from 'app/components/CustomTablePagination';
import { capitalize } from '@mui/material/utils';
import { Button, Modal } from 'app/components';
import {
  selectContactImpersonatedList,
  selectloadImpersonatedClient,
} from 'app/features/AddressBookSlice/selectors';
import { useAddressBookSlice } from 'app/features/AddressBookSlice';
import { useUser } from 'providers/UserProvider';
import { useUserTasksSlice } from 'app/features/UserTasksSlice';
import { useGroupSlice } from 'app/features/GroupSlice';
import { AdvisorTable } from 'app/components/Advisors/AdvisorTable';
import { AddClient } from 'app/components/Advisors/AddClient';
import { getRoutePath } from 'app/routes/routePath';
import { ModalDeleteClient } from 'app/components/Modal/ModalDeleteClient/Loadable';
import { EmptyClientState } from 'app/components/Advisors/EmptyClientState';
import { ConfigurableControlledInput } from 'app/components/Inputs/ControlledInput';
import { useForm } from 'react-hook-form';
import styles from './style';

// Define a type for the modal modes
const MODAL_MODES = {
  NONE: 'none',
  ADD: 'add',
  VIEW: 'view',
  EDIT: 'edit',
};

export function AdvisorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, clearEditState } = useUser();
  const { state } = useLocation();
  const { actions } = useAddressBookSlice();
  const { actions: userTaskActions } = useUserTasksSlice();
  const { actions: groupActions } = useGroupSlice();
  const clients = useSelector(selectContactImpersonatedList);
  const loadImpersonateUser = useSelector(selectloadImpersonatedClient);
  const formMethods = useForm();

  // Table state
  const [tablePage, setTablePage] = useState(0);
  const [tableItemsPerPage, setTableItemsPerPage] = useState(5);
  const [clientList, setClientList] = useState<ImpersonateUserConnection[]>([]);
  const [filteredClients, setFilteredClients] = useState<
    ImpersonateUserConnection[]
  >([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Modal state - simplified to a single mode with current client
  const [modalMode, setModalMode] = useState(MODAL_MODES.NONE);
  const [currentClient, setCurrentClient] = useState<
    ImpersonateUserConnection | undefined
  >(undefined);
  const [deleteClientModal, setDeleteClientModal] = useState(false);
  const [deleteClient, setDeleteClient] = useState<
    ImpersonateUserConnection | undefined
  >(undefined);

  // Consolidation navigation state
  const [goToConsolidation, setGoToConsolidation] = useState(false);

  // Filter clients based on search term
  useEffect(() => {
    if (!clients) return;

    if (!searchTerm.trim()) {
      setFilteredClients(clients);
    } else {
      const term = searchTerm.toLowerCase();
      const filtered = clients.filter(client => {
        const firstName =
          client.connectionManagerMeta?.firstName?.toLowerCase() || '';
        const lastName =
          client.connectionManagerMeta?.lastName?.toLowerCase() || '';
        const email = client.connectionManagerMeta?.email?.toLowerCase() || '';

        return (
          firstName.includes(term) ||
          lastName.includes(term) ||
          email.includes(term) ||
          `${firstName} ${lastName}`.includes(term)
        );
      });

      setFilteredClients(filtered);
    }
  }, [clients, searchTerm]);

  // Handle pagination based on filtered clients
  useEffect(() => {
    const startSlice = tablePage * tableItemsPerPage;
    const endSlice = startSlice + tableItemsPerPage;
    setClientList(filteredClients.slice(startSlice, endSlice));
  }, [filteredClients, tableItemsPerPage, tablePage]);

  // Load client data on component mount
  useEffect(() => {
    dispatch(actions.getContactImpersonatedSummaryList());
  }, [actions, dispatch]);

  // Watch search input changes from the form
  useEffect(() => {
    const subscription = formMethods.watch(value => {
      if (value.clientSearch !== undefined) {
        setSearchTerm(value.clientSearch || '');
      }
    });

    return () => subscription.unsubscribe();
  }, [formMethods]);

  // Handle client modal actions
  const openAddClientModal = () => {
    setCurrentClient(undefined);
    setModalMode(MODAL_MODES.ADD);
  };

  const openViewClientModal = (client: ImpersonateUserConnection) => () => {
    setCurrentClient(client);
    setModalMode(MODAL_MODES.VIEW);
  };

  const openEditClientModal = (client: ImpersonateUserConnection) => () => {
    dispatch(actions.getImpersonateToken({ userId: client.connectionManager }));
    setCurrentClient(client);
    setModalMode(MODAL_MODES.EDIT);
  };

  const closeClientModal = () => {
    dispatch(actions.resetImpersonateToken());
    dispatch(actions.getContactImpersonatedSummaryList());
    clearEditState();
    setModalMode(MODAL_MODES.NONE);
    setCurrentClient(undefined);
  };

  // Handle consolidation view navigation
  const handleGoToConsolidation = (client: ImpersonateUserConnection) => () => {
    const payload = { userId: client?.connectionManager };
    dispatch(actions.loadImpersonatedClient());
    dispatch(actions.getImpersonateToken(payload));
    dispatch(actions.setImpersonatedUser(client));
    setGoToConsolidation(true);
  };

  // Handle delete client actions
  const openDeleteClientModal = (client: ImpersonateUserConnection) => () => {
    setDeleteClient(client);
    setDeleteClientModal(true);
  };

  const closeDeleteClientModal = () => {
    setDeleteClientModal(false);
  };

  const handleRemoveClient = async () => {
    if (deleteClient) {
      const payload = {
        clientId: deleteClient._id,
      };
      dispatch(actions.deleteImpersonateConnection(payload));
    }
    dispatch(actions.getContactImpersonatedSummaryListCleanUp());
    closeDeleteClientModal();
  };

  // Handle navigation to consolidation view and other side effects
  useEffect(() => {
    setDeleteClient(undefined);
    setDeleteClientModal(false);
    dispatch(userTaskActions.cleanUpTasks());
    dispatch(groupActions.cleanupGroupOperation());

    if (loadImpersonateUser && goToConsolidation) {
      dispatch(actions.getContactSummaryListCleanUp());
      sessionStorage.setItem('userImpersonated', 'true');
      navigate(
        getRoutePath({
          target: 'consolidation',
          pathParams: { groupId: '' },
        }),
      );
    }

    // Handle state from location if needed
    if (state?.openModel) {
      state.openModel = false;
      setModalMode(MODAL_MODES.ADD);
    }
  }, [
    actions,
    dispatch,
    groupActions,
    loadImpersonateUser,
    navigate,
    goToConsolidation,
    userTaskActions,
    state?.openModel,
    state,
  ]);

  // Check if modal should be open based on mode
  const isClientModalOpen = modalMode !== MODAL_MODES.NONE;

  // Determine if it's view mode for AddClient component
  const isViewMode = modalMode === MODAL_MODES.VIEW;

  return (
    <Card sx={styles.cardContainerStyles}>
      {/* Delete Client Modal */}
      <ModalDeleteClient
        isOpenModal={deleteClientModal}
        deleteClient={deleteClient}
        onCancel={closeDeleteClientModal}
        onDelete={handleRemoveClient}
      />

      {/* Client Information Modal */}
      <Modal
        contentSx={styles.modelStyles}
        isOpen={isClientModalOpen}
        onClose={closeClientModal}
      >
        <AddClient
          viewClient={isViewMode}
          client={currentClient}
          onClose={closeClientModal}
        />
      </Modal>

      {/* Header with title and subtitle */}
      <Box sx={styles.headerWrapper}>
        <Box>
          <Typography variant="h4" sx={styles.headerTitle}>
            {t('advisors.title')}
          </Typography>
          <Typography variant="body2" sx={styles.headerSubtitle}>
            {t('advisors.subtitle')}
          </Typography>
        </Box>
      </Box>

      {/* Search and Add Clients section */}
      {clients?.length !== 0 && (
        <Box sx={styles.searchAddClientWrapper}>
          {/* Search input using ConfigurableControlledInput */}
          <Box sx={styles.searchContainer}>
            <ConfigurableControlledInput
              formInstance={formMethods}
              name="clientSearch"
              label={t('advisors.search.by.client')}
              placeholder={t('advisors.search.by.name.email')}
              helperText=""
              textFieldProps={{
                InputLabelProps: { shrink: true },
                sx: styles.formField,
              }}
              InputProps={{
                startAdornment: (
                  <Box component="span" sx={styles.searchIcon}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 21L16.65 16.65"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                ),
              }}
            />
          </Box>

          {/* Add Client Button */}
          <Button
            label={t('advisors.sidebar.addNew.button')}
            variant="contained"
            onClick={openAddClientModal}
            sx={{
              ...styles.addClientButton,
              textTransform: 'uppercase',
            }}
            startIcon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 3.33334V12.6667"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.33334 8H12.6667"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
        </Box>
      )}

      {/* Client Table or Empty State */}
      <Box sx={styles.contentWrapper}>
        {clients?.length !== 0 ? (
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={filteredClients?.length || 0}
            onPageChange={setTablePage}
            onRowsPerPageChange={setTableItemsPerPage}
            labelRowsPerPage={capitalize(t('taskManager.labels.itemsPerPage'))}
          >
            <AdvisorTable
              clientList={clientList}
              viewOnClick={openViewClientModal}
              viewConsolidation={handleGoToConsolidation}
              editOnClick={openEditClientModal}
              deleteOnClick={openDeleteClientModal}
            />
          </CustomTablePagination>
        ) : (
          <EmptyClientState
            onGetStarted={openAddClientModal}
            isLoading={Boolean(loadImpersonateUser)}
          />
        )}
      </Box>
    </Card>
  );
}
