import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  List,
  Box,
  Divider,
  ListItemButton,
  Typography,
} from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { Add, Inbox } from '@mui/icons-material';
import { ImpersonateUserConnection } from 'app/features/AddressBookSlice/types';
import {
  selectGroupsByUserId,
  createGroupSuccess,
} from 'app/features/GroupSlice/selectors';
import { useUser } from 'providers/UserProvider';
import { ConsolidatedGroupData, dataStatusTask } from 'constants/keyValue';
import { ListItemAccordion } from 'app/components/ListItemAccordion';
import { ListItemSideBar } from '../ListItemSideBar/Loadable';
import { ModalActivateEntities } from '../ModalActivateEntities/Loadable';
import { ListAdminGroups } from '../ListAdminGroups/Loadable';
import { useNavigate, useParams, useLocation } from 'react-router';
import { ManageEntityModal } from '../../ManageEntityModal/Loadable';
import { useGroupSlice } from 'app/features/GroupSlice';
import { useUserTasksSlice } from 'app/features/UserTasksSlice';
import { getBaseRouteTarget, getRoutePath } from 'app/routes/routePath';
import { SharedWithMeButton } from '../SharedWithMeButton/Loadable';
import SidebarStyles from './style';
import {
  selectContactImpersonatedList,
  selectIsImpersonating,
  selectGetImpersonatedClient,
  selectloadImpersonatedClient,
} from 'app/features/AddressBookSlice/selectors';
import { ListClients } from '../ListClients/Loadable';
import { MembershipType } from 'providers/UserProvider/types';
import { useAddressBookSlice } from 'app/features/AddressBookSlice';
import { selectArchivedTask } from 'app/features/UserTasksSlice/selectors';
import { EntityListAccordion } from '../EntityListAccordion/Loadable';

interface SideBarContentProps {
  clickOpenUpgradeModal: () => void;
  addNewGroup: (event: React.MouseEvent) => void;
  onClickSelectGroup: (event: React.MouseEvent) => void;
}

const {
  buttonLigthGrayStyles,
  sidebarStyles,
  internalSidebarSx,
  dividerStyles,
  expandendAccordionStyles,
  accordionStyles,
  expandendEntityAccordionStyles,
  entitiesDetailsStyles,
  goToEntityButtonStyles,
  addClientButtonStyles,
  noClienttitle,
  buttonStyle,
  outlinedButtonStyles,
  dividerSx,
  listSx,
  clientListBox,
} = SidebarStyles;

export const SideBarContent = ({ onClickSelectGroup }: SideBarContentProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useUser();
  const groupsDataList = useSelector(selectGroupsByUserId);
  const selectArchived = useSelector(selectArchivedTask);
  const { groupId } = useParams();

  const dispatch = useDispatch();
  const { actions: taskActions } = useUserTasksSlice();

  const isScenarioModeling = location.pathname.includes('scenario-modeling');
  const isTasksView = location.pathname.includes('tasks');

  const onClickSelectStatusTask = event => {
    const status = event.currentTarget.id;
    dispatch(taskActions.statusTask(status));
  };

  const onClickSelectArchivedTask = () => {
    dispatch(taskActions.typeTaskArchived(!selectArchived));
  };

  const onClickAddNewTask = () => {
    dispatch(taskActions.addNewTask(true));
  };

  return (
    <>
      {/* 1. Fallback: sidebar contents if tasks */}
      {isTasksView && (
        <>
          <ListItemSideBar
            icon={Add}
            title={t('taskManager.buttons.addNew')}
            button={true}
            sx={buttonStyle}
            onClick={onClickAddNewTask}
          />
          {dataStatusTask.map(data => (
            <ListItemSideBar
              title={data.groupName}
              button={true}
              sx={buttonLigthGrayStyles}
              onClick={onClickSelectStatusTask}
              groupId={data._id}
              isSelected={location.pathname.includes('allMyTasks')}
            />
          ))}
          <Divider variant="fullWidth" sx={dividerSx} />
          <ListItemSideBar
            icon={Inbox}
            title={
              !selectArchived
                ? t('taskManager.buttons.archived')
                : t('taskManager.buttons.viewUnarchived')
            }
            button={true}
            sx={outlinedButtonStyles}
            onClick={onClickSelectArchivedTask}
          />
        </>
      )}

      {/* 2. sidebar contents if NEITHER scenario NOR tasks */}
      {!isScenarioModeling && !isTasksView && (
        <ListItemSideBar
          title={ConsolidatedGroupData.groupName}
          button={true}
          sx={buttonLigthGrayStyles}
          onClick={onClickSelectGroup}
          groupId={ConsolidatedGroupData._id}
          isSelected={ConsolidatedGroupData._id === groupId}
          variant="subtitle2"
        />
      )}

      {!isTasksView && (
        <ListAdminGroups
          groupsDataList={groupsDataList}
          onClickSelectGroup={onClickSelectGroup}
          isTypePersonal={true}
          selectedGroup={groupId}
          variant="subtitle2"
        />
      )}
    </>
  );
};

export const InternalSideBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const location = useLocation();
  const { user } = useUser();
  const clientList = useSelector(selectContactImpersonatedList);
  const isImpersonating = useSelector(selectIsImpersonating);
  const createGroupIsSuccess = useSelector(createGroupSuccess);
  const loadImpersonateUser = useSelector(selectloadImpersonatedClient);
  const isAdvisor = user?.account?.membershipType === MembershipType.ADVISOR;
  const [addGroupModalIsOpen, setAddGroupModalIsOpen] = React.useState(false);
  const [ugradeGroupModalIsOpen, setUgradeGroupModalIsOpen] =
    React.useState(false);
  const [goToConsolidation, setGoToConsolidation] = React.useState(false);
  const dispatch = useDispatch();
  const getCurrentClient = useSelector(selectGetImpersonatedClient);
  const showSidebarClient = getCurrentClient._id.length > 1;
  const { actions: groupActions } = useGroupSlice();
  const { actions: stakeholderActions } = useAddressBookSlice();
  const { actions: userTaskActions } = useUserTasksSlice();

  const addNewGroup = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAddGroupModalIsOpen(true);
  };

  const clickOpenUpgradeModal = () => {
    setUgradeGroupModalIsOpen(true);
  };

  const clickUpgradeModalButton = () => {
    setUgradeGroupModalIsOpen(false);
    setAddGroupModalIsOpen(true);
  };

  const onClickSelectGroup = event => {
    event.stopPropagation();
    const selectedGroupId = event.currentTarget.id;
    if (selectedGroupId && groupId !== selectedGroupId) {
      const basePathTarget = getBaseRouteTarget(location.pathname);
      const shouldRedirect = [
        'consolidation',
        'stakeholder',
        'scenario-modeling',
        'vault',
      ].includes(basePathTarget);
      navigate(
        getRoutePath({
          target: shouldRedirect ? basePathTarget : 'consolidation',
          pathParams: { groupId: selectedGroupId },
        }),
      );
    }
  };

  const isTasksView = location.pathname.includes('tasks');
  const isScenarioModeling = location.pathname.includes('scenario-modeling');

  const sidebarTitleString = isScenarioModeling
    ? 'scenarioModeling.headers.scenarioModeling'
    : isTasksView
      ? 'myTaskManager'
      : 'myFamilyOffice';

  const handleEntityModalSuccess = (entityData: {
    groupType: string;
    groupName: string;
    groupStakePercentage: number;
    groupAdmin?: string;
    groupId?: string;
    groupOwnership?: string;
  }) => {
    setAddGroupModalIsOpen(false);
    dispatch(groupActions.createGroup(entityData));
  };

  const goToConsolidationView =
    (client: ImpersonateUserConnection) => async () => {
      const payload = { userId: client?.connectionManager };
      dispatch(stakeholderActions.loadImpersonatedClient());
      dispatch(stakeholderActions.getImpersonateToken(payload));
      dispatch(stakeholderActions.setImpersonatedUser(client));
      setGoToConsolidation(true);
    };

  React.useEffect(() => {
    if (!createGroupIsSuccess) return;
    dispatch(groupActions.cleanupGroupOperation());
    dispatch(stakeholderActions.getContactSummaryList());
  }, [createGroupIsSuccess, dispatch, groupActions, stakeholderActions]);

  React.useEffect(() => {
    dispatch(userTaskActions.cleanUpTasks());
    dispatch(groupActions.cleanupGroupOperation());
    if (loadImpersonateUser && goToConsolidation) {
      setGoToConsolidation(false);
      dispatch(stakeholderActions.getContactSummaryListCleanUp());
      sessionStorage.setItem('userImpersonated', 'true');
      navigate(
        getRoutePath({
          target: 'consolidation',
          pathParams: { groupId: '' },
        }),
      );
    }
  }, [
    dispatch,
    loadImpersonateUser,
    groupActions,
    navigate,
    goToConsolidation,
    stakeholderActions,
    userTaskActions,
  ]);

  return (
    <Drawer
      variant="permanent"
      sx={internalSidebarSx}
      PaperProps={{ elevation: 12, sx: sidebarStyles }}
    >
      <ManageEntityModal
        mode="create"
        isOpenModal={addGroupModalIsOpen}
        setIsOpenModal={setAddGroupModalIsOpen}
        onComplete={handleEntityModalSuccess}
      />

      <ModalActivateEntities
        isOpenModal={ugradeGroupModalIsOpen}
        setIsOpenModal={setUgradeGroupModalIsOpen}
        onClickButton={clickUpgradeModalButton}
      />

      <Box>
        {/* Internal Sidebar contents for advisor */}
        {isAdvisor && (
          <List sx={listSx}>
            <Divider variant="fullWidth" sx={dividerStyles} />
            <ListItemAccordion
              title={ListItemSideBar({
                title: capitalize(t('advisors.title')),
                variant: 'subtitle2',
              })}
              accordionSx={accordionStyles}
              contentListSx={expandendAccordionStyles}
              accordionDetailsSx={entitiesDetailsStyles}
              accordionSummarySx={expandendEntityAccordionStyles}
              defaultExpanded={true}
              name="clients"
            >
              <Box sx={clientListBox}>
                <ListClients
                  clientList={clientList}
                  viewConsolidation={goToConsolidationView}
                  isAdvisor={true}
                />
              </Box>
              {!showSidebarClient && clientList.length > 0 && (
                <ListItemButton
                  onClick={() => {
                    dispatch(stakeholderActions.resetImpersonateToken());
                    navigate(
                      getRoutePath({
                        target: 'advisors',
                      }),
                      { state: { openModel: true } },
                    );
                  }}
                  sx={{ ...buttonLigthGrayStyles, mb: '8px' }}
                >
                  <Typography variant="buttonMedium">
                    {capitalize(t('advisors.sidebar.addNewClient'))}
                  </Typography>
                </ListItemButton>
              )}
              {clientList.length === 0 && (
                <Typography variant="subtitle2" sx={noClienttitle}>
                  {capitalize(t('advisors.sidebar.noClients'))}
                </Typography>
              )}
              {showSidebarClient && (
                <ListItemButton
                  onClick={() => {
                    dispatch(stakeholderActions.resetImpersonateToken());
                    navigate(
                      getRoutePath({
                        target: 'advisors',
                      }),
                    );
                  }}
                  sx={addClientButtonStyles}
                >
                  <Typography variant="buttonMedium">
                    {clientList.length > 0
                      ? capitalize(t('advisors.sidebar.title'))
                      : capitalize(t('advisors.sidebar.addNew.button'))}
                  </Typography>
                </ListItemButton>
              )}
              <Divider variant="fullWidth" sx={dividerStyles} />
              {showSidebarClient && (
                <>
                  <SideBarContent
                    addNewGroup={addNewGroup}
                    clickOpenUpgradeModal={clickOpenUpgradeModal}
                    onClickSelectGroup={onClickSelectGroup}
                  />
                  <Divider variant="fullWidth" sx={dividerStyles} />
                  {!isTasksView && (
                    <EntityListAccordion
                      addNewGroup={addNewGroup}
                      clickOpenUpgradeModal={clickOpenUpgradeModal}
                      isScenarioModeling={isScenarioModeling}
                      onClickSelectGroup={onClickSelectGroup}
                    />
                  )}
                </>
              )}
            </ListItemAccordion>
          </List>
        )}
        {isAdvisor ? (
          // 'Shared with me' button is hidden when an advisor views a client's profile
          !showSidebarClient && (
            <SharedWithMeButton
              addNewGroup={addNewGroup}
              isAdvisor={isAdvisor}
              clickOpenUpgradeModal={clickOpenUpgradeModal}
              onClickSelectGroup={onClickSelectGroup}
              isImpersonating={isImpersonating}
              isScenarioModeling={isScenarioModeling}
            />
          )
        ) : (
          <List sx={listSx}>
            {/* MyFo Section */}
            <Typography variant="h6" sx={{ pb: '6px' }}>
              {capitalize(t(sidebarTitleString))}
            </Typography>
            <Divider variant="fullWidth" sx={dividerStyles} />
            <SideBarContent
              addNewGroup={addNewGroup}
              clickOpenUpgradeModal={clickOpenUpgradeModal}
              onClickSelectGroup={onClickSelectGroup}
            />
            {!isScenarioModeling && !isTasksView && (
              <>
                <SharedWithMeButton
                  addNewGroup={addNewGroup}
                  isAdvisor={isAdvisor}
                  clickOpenUpgradeModal={clickOpenUpgradeModal}
                  onClickSelectGroup={onClickSelectGroup}
                  isImpersonating={isImpersonating}
                  isScenarioModeling={isScenarioModeling}
                />
                {/* This button below is displayed for impersonating non-advisor users */}
                {isImpersonating && (
                  <ListItemButton
                    onClick={() => {
                      dispatch(stakeholderActions.signOutImpersonateUser());
                      navigate(
                        getRoutePath({
                          target: 'consolidation',
                          pathParams: { groupId: '' },
                        }),
                      );
                    }}
                    sx={goToEntityButtonStyles}
                  >
                    <Typography variant="buttonMedium">
                      {capitalize(t('sideBar.buttons.logOutImpersonate'))}
                    </Typography>
                  </ListItemButton>
                )}
              </>
            )}
            <Divider variant="fullWidth" sx={dividerStyles} />
            {!isTasksView && (
              <EntityListAccordion
                addNewGroup={addNewGroup}
                clickOpenUpgradeModal={clickOpenUpgradeModal}
                isScenarioModeling={isScenarioModeling}
                onClickSelectGroup={onClickSelectGroup}
              />
            )}
          </List>
        )}
      </Box>
    </Drawer>
  );
};
