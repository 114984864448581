import { ValidCurrencies } from 'app/features/CurrencySlice/types';
const currencyToLocalesMap = {
  DZD: 'ar-SA',
  BND: 'bn-BD',
  INR: 'en-IN',
  CZK: 'cs-CZ',
  DKK: 'da-DK',
  EUR: 'de-DE',
  AUD: 'en-AU',
  CAD: 'en-CA',
  GBP: 'en-GB',
  NZD: 'en-NZ',
  USD: 'en-US',
  ZAR: 'en-ZA',
  ARS: 'es-AR',
  CLP: 'es-CL',
  COP: 'es-CO',
  MXN: 'es-MX',
  CHF: 'fr-CH',
  HUF: 'hu-HU',
  IDR: 'id-ID',
  JPY: 'ja-JP',
  KRW: 'ko-KR',
  NOK: 'no-NO',
  PLN: 'pl-PL',
  BRL: 'pt-BR',
  ROL: 'ro-RO',
  RUB: 'ru-RU',
  SKK: 'sk-SK',
  SEK: 'sv-SE',
  LKR: 'ta-LK',
  THB: 'th-TH',
  TRL: 'tr-TR',
  CNY: 'zh-CN',
  HKD: 'zh-HK',
  TWD: 'zh-TW',
};

export default function getLocaleFromCurrencyCode(
  currencyCode: ValidCurrencies,
): string {
  if (currencyToLocalesMap[currencyCode]) {
    return currencyToLocalesMap[currencyCode];
  }

  return 'en-US';
}
