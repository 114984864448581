import { SxProps, Theme } from '@mui/material';
import { muiTheme } from 'styles/muiTheme';

const style: Record<string, SxProps<Theme>> = {
  container: {
    width: '100%',
  },
  // Typography & Header styles
  title: {
    ...muiTheme.typography.h5,
    marginBottom: '16px',
  },
  headerStyle: {
    marginBottom: '16px',
    borderBottom: '1px solid',
    borderColor: muiTheme.palette.other.divider,
    paddingBottom: '8px',
  },
  subheading: {
    ...muiTheme.typography.subtitle1,
    marginBottom: '24px',
  },
  inputLabel: {
    ...muiTheme.typography.subtitle2,
    marginBottom: '4px',
  },
  // Form styles
  formContainer: {
    width: '100%',
  },
  textField: {
    marginBottom: '16px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
  },
  radioGroup: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  divider: {
    marginBottom: '24px',
  },
  helperText: {
    ...muiTheme.typography.helperText,
    marginTop: '4px',
  },
  // Button styles
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '32px',
    paddingTop: '16px',
    borderTop: `1px solid ${muiTheme.palette.other.divider}`,
  },
  cancelButton: {
    padding: '8px 16px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    ...muiTheme.typography.buttonMedium,
    color: muiTheme.palette.text.primary,
  },
  submitButton: {
    padding: '8px 16px',
    backgroundColor: muiTheme.palette.primary.back,
    color: muiTheme.palette.primary.contrast,
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    ...(muiTheme.typography.buttonMedium as any),
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
    '&:hover': {
      backgroundColor: muiTheme.palette.primary.medium,
    },
  },
};

export default style;
