import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  FolderOutlined,
  ExpandMore,
  MoreVert,
  VisibilityOutlined,
  Edit,
  FolderOpenOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from '@mui/material';
import { VaultFile, Folder } from 'app/pages/vault/models/vault.types';
import { FileRow } from './components/file-row';
import { ASSET_ROW_WIDTH, DEPTH_SPACE } from '../../utils/files.utils';
import { bytesToMB } from 'utils/files';
import { isFolder } from '../../utils/guards.utils';

import * as style from './style';

type Props = {
  item: Folder | VaultFile;
  onPreviewFile: (file: VaultFile) => void;
  onAddNewFile: (folder: Folder) => void;
  onAddNewLink: () => void;
  onRename: (file: VaultFile) => void;
  onDelete: (file: VaultFile) => void;
  onMoveToFolder: (file: VaultFile) => void;
  depth: number;
  isReadOnly: boolean;
};

export const FolderAccordion = ({
  item,
  onPreviewFile,
  onAddNewFile,
  onAddNewLink,
  onRename,
  onDelete,
  onMoveToFolder,
  depth,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAddNewFile = (item: Folder) => {
    onAddNewFile(item);
  };

  //TODO: Enable this when we need to add a new link
  /*   const handleAddNewLink = () => {
    onAddNewLink();
  }; */

  useEffect(() => {
    if (item?.isIncluded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [item?.isIncluded]);

  return (
    <div>
      <Accordion
        sx={style.accordion}
        expanded={expanded}
        onChange={handleExpansion}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            ...style.accordionSummary,
            backgroundColor: item?.isHighlighted
              ? 'addition.lightGreyLabelColor'
              : 'inherit',
          }}
        >
          {isFolder(item) ? (
            <Grid sx={style.accordionHeader}>
              <Grid display="flex" flexDirection="row" width="30%">
                <>
                  {expanded ? (
                    <FolderOpenOutlined
                      sx={{
                        color: depth > 1 ? 'primary.light' : 'primary.main',
                      }}
                    />
                  ) : (
                    <FolderOutlined
                      sx={{
                        color: depth > 1 ? 'primary.light' : 'primary.main',
                      }}
                    />
                  )}
                </>

                <Typography marginLeft={1} variant="body1">
                  {item.name}
                </Typography>
              </Grid>

              <Grid sx={{ width: '15%' }}>
                <Typography variant="body1" sx={style.textEllipsis}>
                  {item?.itemType}
                </Typography>
              </Grid>
              <Grid sx={{ width: '15%' }}>
                <Typography variant="body1">{item?.owner}</Typography>
              </Grid>
              <Grid
                sx={{ width: '16%', paddingLeft: `${depth * DEPTH_SPACE}px` }}
              >
                <Typography variant="body1">
                  {moment(item?.lastModified).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Grid
                sx={{ width: '16%', paddingLeft: `${depth * DEPTH_SPACE}px` }}
              >
                <Typography variant="body1">
                  {bytesToMB.format(item?.size || 0)}
                </Typography>
              </Grid>
              <Grid>
                {depth > 1 && !isReadOnly && (
                  <IconButton sx={{ padding: 0 }} onClick={handleClick}>
                    <MoreVert />
                  </IconButton>
                )}
              </Grid>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <MenuItem onClick={() => handleAddNewFile(item)}>
                  <IconButton disableRipple>
                    <VisibilityOutlined />
                  </IconButton>
                  <Typography variant="body2">
                    {t('vault.labels.addNewFile')}
                  </Typography>
                </MenuItem>
                {/*    
                
                  //TODO: enable when links from the API are enabled
                <MenuItem onClick={handleAddNewLink}>
                  <IconButton disableRipple>
                    <Edit />
                  </IconButton>
                  <Typography variant="body2">Add External Link</Typography>
                </MenuItem> */}
              </Menu>
            </Grid>
          ) : null}
        </AccordionSummary>

        <AccordionDetails sx={style.accordionDetails}>
          {isFolder(item) && item.children?.length ? (
            <div
              style={{
                paddingLeft: `${depth * DEPTH_SPACE}px`,
              }}
            >
              {item.children.map(child =>
                isFolder(child) ? (
                  <FolderAccordion
                    key={child.name}
                    item={child}
                    depth={depth + 1}
                    onPreviewFile={onPreviewFile}
                    onAddNewFile={onAddNewFile}
                    onAddNewLink={onAddNewLink}
                    onRename={onRename}
                    onDelete={onDelete}
                    onMoveToFolder={onMoveToFolder}
                    isReadOnly={isReadOnly}
                  />
                ) : (
                  <FileRow
                    key={child.fileName}
                    file={child as VaultFile}
                    depth={depth + 1}
                    onPreviewFile={onPreviewFile}
                    onRename={onRename}
                    onDelete={onDelete}
                    onMoveToFolder={onMoveToFolder}
                    isReadOnly={isReadOnly}
                  />
                ),
              )}
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
