import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  selectGetImpersonatedClient,
  selectIsImpersonating,
} from 'app/features/AddressBookSlice/selectors';
import { getRoutePath } from 'app/routes/routePath';
import clientContextStyle from './style';

/**
 * ClientContextBanner Component
 *
 * Displays a persistent banner when an advisor is viewing a client's account
 * Shows the client's name and provides a button to return to all clients
 */
export const ClientContextBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Local state to store client name for persistence
  const [persistentClientName, setPersistentClientName] =
    useState<string>('Client');

  // Use existing selectors to check if user is impersonating and get client data
  const isImpersonating = useSelector(selectIsImpersonating);
  const currentClient = useSelector(selectGetImpersonatedClient);

  useEffect(() => {
    // Force layout recalculation
    window.dispatchEvent(new Event('resize'));
  }, [isImpersonating]);

  // Effect to handle client name persistence
  useEffect(() => {
    const storedClientName = sessionStorage.getItem('impersonatedClientName');

    if (storedClientName) {
      setPersistentClientName(storedClientName);
    } else if (currentClient) {
      const runtimeClient = currentClient as any;
      if (runtimeClient.name) {
        sessionStorage.setItem('impersonatedClientName', runtimeClient.name);
        setPersistentClientName(runtimeClient.name);
      }
    }
  }, [currentClient]);

  // If not impersonating, don't show the banner
  if (!isImpersonating) return null;

  const handleGoBack = () => {
    // Clear the impersonation flags in session storage
    sessionStorage.removeItem('userImpersonated');
    sessionStorage.removeItem('impersonatedClientName');

    navigate(
      getRoutePath({
        target: 'advisors',
        pathParams: {},
      }),
    );
  };

  return (
    <AppBar position="static" sx={clientContextStyle.appBar} elevation={0}>
      <Toolbar sx={clientContextStyle.toolbar}>
        <Typography variant="body1" sx={clientContextStyle.bannerText}>
          {t('clientContext.currentlyInAccount')}{' '}
          <strong>{persistentClientName}</strong>&apos;s{' '}
          {t('clientContext.asAdvisor')}
        </Typography>

        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          size="small"
          sx={clientContextStyle.backButton}
        >
          {t('clientContext.goBackToAllClients')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default ClientContextBanner;
