import { Button, Stack, Typography } from '@mui/material';
import { muiTheme } from 'styles/muiTheme';

type BottomRightGroupButtonsProps = {
  onPrimaryBtnClick?: () => unknown;
  onSecondaryBtnClick?: () => unknown;
  onCancelBtnClick?: () => unknown;
  primaryBtnText?: string;
  secondaryBtnText?: string | React.ReactNode;
  cancelBtnText?: string;
  secondaryBtnDisabled?: boolean;
  cancelBtnDisabled?: boolean;
  secondaryFullWidth?: boolean;
  cancelFullWidth?: boolean;
  primaryBtnStyles?: { [key: string]: string };
  secondaryBtnStyles?: { [key: string]: string };
  cancelBtnStyles?: { [key: string]: string };
  buttonGroupStyles?: string;
  secondaryBtnVariant?: 'contained' | 'text' | 'outlined';
  cancelBtnVariant?: 'contained' | 'text' | 'outlined';
  secondaryBtnTxtVariant?: 'body1' | 'buttonLarge';
  cancelBtnTxtVariant?: 'body1' | 'buttonLarge';
};

export const BottomRightGroupButtons = ({
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  onCancelBtnClick,
  primaryBtnText,
  secondaryBtnText,
  cancelBtnText,
  secondaryBtnDisabled = false,
  cancelBtnDisabled = true,
  secondaryFullWidth = false,
  cancelFullWidth = false,
  primaryBtnStyles,
  secondaryBtnStyles,
  cancelBtnStyles,
  buttonGroupStyles = 'end',
  secondaryBtnVariant = 'contained',
  cancelBtnVariant = 'text',
  secondaryBtnTxtVariant = 'buttonLarge',
  cancelBtnTxtVariant = 'buttonLarge',
}: BottomRightGroupButtonsProps) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{ justifyContent: buttonGroupStyles }}
    >
      {primaryBtnText && (
        <Button
          variant="text"
          sx={{
            ...muiTheme.variants.bottomRightButtonPrimary,
            ...primaryBtnStyles,
          }}
          onClick={onPrimaryBtnClick}
        >
          <Typography variant={secondaryBtnTxtVariant}>
            {primaryBtnText}
          </Typography>
        </Button>
      )}
      {secondaryBtnText && (
        <Button
          type="submit"
          variant={secondaryBtnVariant}
          fullWidth={secondaryFullWidth}
          sx={{
            ...muiTheme.variants.bottomRightButtonSecondary,
            ...secondaryBtnStyles,
          }}
          onClick={onSecondaryBtnClick}
          disabled={secondaryBtnDisabled}
        >
          <Typography variant={secondaryBtnTxtVariant}>
            {secondaryBtnText}
          </Typography>
        </Button>
      )}
      {cancelBtnText && (
        <Button
          type="button"
          variant={cancelBtnVariant}
          fullWidth={cancelFullWidth}
          sx={{
            ...muiTheme.variants.bottomRightButtonCancel,
            ...cancelBtnStyles,
          }}
          onClick={onCancelBtnClick}
        >
          <Typography variant={cancelBtnTxtVariant}>{cancelBtnText}</Typography>
        </Button>
      )}
    </Stack>
  );
};
