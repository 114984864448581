export const REGULAR_EXPRESSION_EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+?^_`{|}~-]+@[a-zA-Z0-9-](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9-])?[.][a-zA-Z0-9-](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9-]?)*$/;

export const REGULAR_EXPRESSION_PHONE =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const REGULAR_EXPRESSION_NUMBERS_ONLY = /^\d/;

export const REGULAR_EXPRESSION_ISO_DATE =
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
