import { Box, Autocomplete, BaseTextFieldProps } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import CL from 'country-telephone-data';
import { Controller, UseFormReturn, ValidationRule } from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import * as t from 'io-ts';
import { Input, InputProps } from '../Input';
import { useTranslation } from 'react-i18next';
import { getInputErrors, getRequiredRuleValue } from '../utils';

export const CountryCodec = t.type({
  name: t.string,
  iso2: t.string,
  dialCode: t.string,
  priority: t.number,
  format: t.string,
});
export type Country = t.TypeOf<typeof CountryCodec>;

export interface ConfigurableControlledCountryProps {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  format: string;
}

export const countries = (
  CL.allCountries as ConfigurableControlledCountryProps[]
)
  .sort((a, b) => a.priority - b.priority)
  .sort((a, b) => parseInt(a.dialCode) - parseInt(b.dialCode));

interface ControlledInputCountryBaseProps
  extends Omit<BaseTextFieldProps, 'defaultValue' | 'onChange'> {
  name: string;
  label?: string;
  size?: 'small' | 'medium';
  enabledCountriesIsos?: string[];
  disabled?: boolean;
  useFormInstance: UseFormReturn<any>;
  otherRules?: Record<string, ValidationRule>;
  textFieldProps?: Partial<InputProps>;
  useHelperTextIcon?: boolean;
}

export interface CountrySelectProps extends BaseTextFieldProps {
  label?: string;
  name: string;
  size?: 'small' | 'medium';
  enabledCountriesIsos?: string[];
  disabled?: boolean;
  useFormInstance: UseFormReturn<any>;
  otherRules?: Record<string, ValidationRule>;
}

/**
 * Props interface for country selection input
 * @deprecated - Will be consolidated with standardized input interfaces (https://myfo.atlassian.net/browse/MSP-2631)
 * @see ConfigurableControlledInputProps for a similar interface pattern
 */
export interface ConfigurableControlledCountrySelectProps
  extends Omit<BaseTextFieldProps, 'defaultValue' | 'onChange'> {
  label?: string;
  name: string;
  size?: 'small' | 'medium';
  enabledCountriesIsos?: string[];
  disabled?: boolean;
  useFormInstance: UseFormReturn<any>;
  otherRules?: Record<string, ValidationRule>;
  textFieldProps?: Partial<InputProps>;
}

const ControlledInputCountryBase = (props: ControlledInputCountryBaseProps) => {
  const {
    name,
    useFormInstance,
    label,
    disabled,
    enabledCountriesIsos,
    otherRules,
    textFieldProps = {},
    useHelperTextIcon = false,
    ...inputProps
  } = props;

  const { control, getFieldState } = useFormInstance;
  const { t } = useTranslation();
  const isRequired = getRequiredRuleValue({ otherRules, disabled });
  const { hasError, errorMessage } = getInputErrors({
    name,
    disabled,
    getFieldState,
  });

  const helperMessage =
    errorMessage || (isRequired && capitalize(t('thisFieldIsRequired'))) || '';

  const enabledCountries = countries
    .filter(c => !enabledCountriesIsos || enabledCountriesIsos.includes(c.iso2))
    .map(({ name }) => name);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: !disabled,
          message: capitalize(t('thisFieldIsRequired')),
        },
        ...otherRules,
      }}
      render={({ field: { ref, ...field } }) => (
        <Autocomplete
          {...field}
          onChange={(e, data) => field.onChange(data)}
          id={name}
          options={enabledCountries}
          disabled={disabled}
          autoHighlight
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option}
            </Box>
          )}
          renderInput={params => (
            <Input
              {...params}
              {...inputProps}
              {...textFieldProps}
              key={inputProps.key}
              error={hasError}
              HelperTextIcon={useHelperTextIcon ? InfoOutlined : undefined}
              helperText={textFieldProps.helperText || helperMessage}
              label={textFieldProps.label || label || capitalize(t('country'))}
              inputProps={params.inputProps}
              disabled={disabled}
            />
          )}
        />
      )}
    />
  );
};

export const ControlledInputCountry = (props: CountrySelectProps) => {
  const { ...restProps } = props;
  return <ControlledInputCountryBase useHelperTextIcon={true} {...restProps} />;
};

export const ConfigurableControlledCountry = (
  props: ConfigurableControlledCountrySelectProps,
) => {
  const { ...restProps } = props;
  return <ControlledInputCountryBase {...restProps} />;
};
