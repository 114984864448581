import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';
import styles from './style';

interface FilterOption {
  label: string;
  checked: boolean;
}

interface StakeholderFilterPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  filterOptions: Record<string, FilterOption>;
  setFilterOptions: (options: Record<string, FilterOption>) => void;
}

/**
 * FilterPopover Component - Displays filter options in a popover with delayed filtering
 * Filtering is only applied when the user clicks "APPLY FILTERS" button
 */
const StakeholderFilterPopover = ({
  open,
  anchorEl,
  onClose,
  filterOptions,
  setFilterOptions,
}: StakeholderFilterPopoverProps) => {
  const { t } = useTranslation();

  const [tempFilters, setTempFilters] =
    useState<Record<string, FilterOption>>(filterOptions);

  useEffect(() => {
    if (open) {
      setTempFilters(filterOptions);
    }
  }, [open, filterOptions]);

  const handleClearAll = () => {
    const clearedOptions = Object.keys(tempFilters).reduce(
      (acc, key) => ({
        ...acc,
        [key]: { ...tempFilters[key], checked: false },
      }),
      {} as Record<string, FilterOption>,
    );
    setTempFilters(clearedOptions);
  };

  const handleCheckboxChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTempFilters(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        checked: event.target.checked,
      },
    }));
  };

  const handleApplyFilters = () => {
    setFilterOptions(tempFilters);
    onClose();
  };

  const handleCancel = () => {
    setTempFilters(filterOptions);
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCancel}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: styles.paper,
      }}
    >
      <Box>
        <Box sx={styles.header}>
          <Typography sx={styles.headerTitle}>
            {t('stakeholder.filters')}
          </Typography>
          <Box sx={styles.headerActions}>
            <Typography
              variant="caption"
              sx={styles.clearAllText}
              onClick={handleClearAll}
            >
              {t('stakeholder.clearAll')}
            </Typography>
            <IconButton
              size="small"
              onClick={handleCancel}
              sx={styles.closeButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        <Box sx={styles.filterOptions}>
          {Object.entries(tempFilters).map(([key, value]) => (
            <FormControlLabel
              key={key}
              sx={styles.formControlLabel}
              label={capitalize(value.label)}
              control={
                <Checkbox
                  size="small"
                  checked={value.checked}
                  onChange={event => handleCheckboxChange(key, event)}
                  name={key}
                />
              }
            />
          ))}
        </Box>

        <Box sx={styles.buttonContainer}>
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={handleApplyFilters}
            sx={styles.applyButton}
          >
            {t('stakeholder.applyFilters')}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default StakeholderFilterPopover;
