import { muiTheme } from 'styles/muiTheme';

export default {
  boxContainerStyles: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginBottom: '24px',
    width: '100%',
  },
  formLabel: {
    ...muiTheme.typography.subtitle2,
    marginBottom: '4px',
  },
  helperText: {
    ...muiTheme.typography.helperText,
    marginTop: '4px',
    color: muiTheme.palette.text.secondary,
  },
  formField: {
    width: '100%',
  },
};
