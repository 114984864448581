export default {
  cardContainerStyles: {
    padding: 0,
    boxShadow: 'none',
    borderRadius: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '16px 24px 8px',
    width: '100%',
  },

  headerTitle: {
    fontWeight: 500,
    color: 'text.primary',
    fontSize: '24px',
    lineHeight: 1.2,
    marginBottom: '4px',
  },

  headerSubtitle: {
    color: 'text.secondary',
    fontSize: '14px',
    lineHeight: 1.5,
  },

  searchAddClientWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 24px',
    width: '100%',
    height: '100px',
  },

  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '700px',
  },

  searchIcon: {
    color: 'text.secondary',
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },

  searchInputStyles: {
    width: '100%',
    '& .MuiInputBase-root': {
      height: '40px',
      borderRadius: '4px',
    },
    '& .MuiInputBase-input': {
      height: '40px',
      padding: '8px 12px 8px 0',
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      color: 'text.secondary',
      transform: 'none',
      position: 'relative',
      marginBottom: '8px',
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0,
      height: '40px',
    },
  },

  headerDivider: {
    width: '100%',
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },

  contentWrapper: {
    padding: '0',
    marginLeft: '24px',
    marginRight: '24px',
    flex: 1,
  },

  addClientButton: {
    textTransform: 'none',
    color: 'primary.contrast',
    padding: '8px 16px',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '14px',
    minWidth: '130px',
    height: '40px',
    boxShadow: 'none',
    marginLeft: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modelStyles: {
    width: '576px',
  },

  // Empty state styling
  noClientStyles: {
    display: 'grid',
    gap: 1,
    alignItems: 'center',
    justifyItems: 'center',
    padding: '28px 30px',
    margin: '24px',
    backgroundColor: 'secondary.12p',
    border: `1px solid`,
    borderColor: 'secondary.8p',
    borderRadius: '4px',
  },
  formField: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
};
