export default {
  cardStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 20px',
    margin: '0 12px 8px 12px',
    backgroundColor: 'white',
    border: `1px solid`,
    borderColor: 'primary.30p',
    borderRadius: '4px',
    width: 'initial',
    minHeight: '74px',
  },
  boxButtons: { display: 'flex', justifyContent: 'right' },
  menuItemStyles: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  iconStyles: {
    color: 'action.active',
  },
  chipStackStyles: {
    flexWrap: 'wrap',
    gap: '4px',
    '& > *': { mb: 0.5 },
  },
};
