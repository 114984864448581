export default {
  boxStyles: {
    border: '1px solid',
    borderColor: 'other.divider',
    backgroundColor: 'primary.contrast',
    borderRadius: '8px',
    minHeight: '500px',
    width: '100%',
    marginTop: '16px',
    marginBottom: '24px',
    overflow: 'hidden',
  },
  topBorderStyles: {
    backgroundColor: 'primary.main',
    height: '1px',
    width: '100%',
    borderRadius: 0,
  },
  headerStakeholderStyles: {
    borderBottom: '1px solid',
    borderColor: 'other.divider',
    padding: '16px 0 12px 0',
    backgroundColor: 'primary.contrast',
  },
  headerNameColumn: {
    paddingLeft: '35px',
  },
  headerContactColumn: {
    paddingLeft: 0,
  },
  headerOptionsColumn: {
    textAlign: 'right',
    paddingRight: '30px',
  },
  headerText: {
    textTransform: 'capitalize',
    fontSize: '0.8rem',
    fontWeight: 700,
    color: 'text.secondary',
  },
  defaultButtonStyles: {
    textTransform: 'uppercase',
    marginLeft: '12px',
    marginBottom: '12px',
    color: 'primary.contrast',
    padding: '8px 22px',
  },
  containerSx: {
    padding: '0',
  },
};
