import { getCurrencySymbol } from 'app/components/CurrencyValue';
import { MaskedTypes } from 'app/components/Inputs/Input/model';
import IMask from 'imask';
import { ValidCurrencies } from 'providers/CurrencyProvider/types';

const numberMaskOptions = {
  mask: Number,
  scale: 0,
  signed: false,
  thousandsSeparator: ',',
  radix: '.',
  normalizeZeros: true,
};

const decimalMaskOptions = {
  ...numberMaskOptions,
  scale: 6,
  normalizeZeros: false,
};

export function createCurrencyMask(
  currency: ValidCurrencies = 'USD',
  signed = false,
) {
  const symbol = getCurrencySymbol(currency);
  return new IMask.MaskedPattern({
    mask: `${symbol}num`,
    blocks: {
      num: { ...decimalMaskOptions, signed },
    },
  });
}

export function createFigureCurrencyMask(currency: ValidCurrencies = 'USD') {
  return createCurrencyMask(currency, true);
}

export function createPercentageMask() {
  return new IMask.MaskedPattern({
    mask: 'num',
    blocks: {
      num: { ...decimalMaskOptions, min: 0, max: 100 },
    },
  });
}
export function createNumberMask() {
  return new IMask.MaskedPattern({
    mask: 'num',
    blocks: {
      num: { ...numberMaskOptions, signed: true },
    },
  });
}
export function createDecimalMask() {
  return new IMask.MaskedPattern({
    mask: 'num',
    blocks: {
      num: { ...decimalMaskOptions, min: 0 },
    },
  });
}
export function createCountryCodeMask() {
  return new IMask.MaskedPattern({
    mask: '+num',
    blocks: {
      num: { ...numberMaskOptions, min: 1 },
    },
  });
}

const TypeMaskRelation: Record<MaskedTypes, () => IMask.MaskedPattern<string>> =
  {
    currency: createCurrencyMask,
    figurecurrency: createFigureCurrencyMask,
    percentage: createPercentageMask,
    figure: createNumberMask,
    decimal: createDecimalMask,
    countryCode: createCountryCodeMask,
  };

export const getUnmaskedValue = (
  value?: string,
  type?: string,
  currency?: ValidCurrencies,
): string => {
  const mask = TypeMaskRelation[type || ''];
  if (mask && value) {
    const resolvedMask = mask(currency);
    resolvedMask.resolve(value);
    return resolvedMask.unmaskedValue;
  }
  return value || '';
};

export const getMaskedValue = (
  val?: string,
  type?: string,
  currency?: ValidCurrencies,
): string => {
  const mask = TypeMaskRelation[type || ''];
  if (mask && val) {
    const resolvedMask = mask(currency);
    resolvedMask.resolve(val);
    return resolvedMask.value;
  }
  return val || '';
};
