import React, { useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddressBookSlice } from 'app/features/AddressBookSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from 'providers/UserProvider';
import {
  selectImpersonateToken,
  selectImpersonateTokenSuccess,
  selectClientAdditionSuccess,
} from 'app/features/AddressBookSlice/selectors';
import {
  ImpersonateUserConnection,
  UserBasicInfo,
} from 'app/features/AddressBookSlice/types';

import { ClientInformationForm } from '../../Forms/ClientInformationForm';
import style from './style';

export interface UserBasicInfoWithInvite extends UserBasicInfo {
  inviteClient?: boolean;
}

export function AddClient({
  onClose,
  client,
  viewClient,
}: {
  client?: ImpersonateUserConnection;
  viewClient: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { editClient, editUserSuccess } = useUser();
  const dispatch = useDispatch();
  const { actions } = useAddressBookSlice();
  const impersonateToken = useSelector(selectImpersonateToken);
  const impersonateTokenSuccess = useSelector(selectImpersonateTokenSuccess);
  const clientAdditionSuccess = useSelector(selectClientAdditionSuccess);

  // Determine the current mode
  const isAddNewClient = !client;
  const isEditClient = client && !viewClient;

  const useFormInstance = useForm<UserBasicInfoWithInvite>({
    mode: 'onBlur',
    defaultValues: {
      firstName: client?.connectionManagerMeta.firstName || '',
      lastName: client?.connectionManagerMeta.lastName || '',
      company: client?.connectionManagerMeta.company || '',
      email: client?.connectionManagerMeta.email || '',
      phoneNumber: client?.connectionManagerMeta.phoneNumber || '',
      countryCode: client?.connectionManagerMeta.countryCode || '',
      country: client?.connectionManagerMeta.country || '',
      // Only default to true for new clients, otherwise use the client's actual invite status or undefined
      // WIP: inviteClient: isAddNewClient ? true : client?.inviteStatus || undefined, (https://myfo.atlassian.net/browse/MSP-2165)
      // for now, it will default
      inviteClient: true,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
    setValue,
    watch,
  } = useFormInstance;

  const inviteClient = watch('inviteClient');

  const handleInviteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    setValue('inviteClient', value);
  };

  const onSubmit = async (formData: UserBasicInfoWithInvite) => {
    // Extract relevant form data
    const userInformation: Record<string, any> = Object.entries(
      formData,
    ).reduce((obj, [key, val]) => {
      if (val !== undefined && key !== 'inviteClient') {
        obj[key] = val;
      }
      return obj;
    }, {});

    // Convert boolean to expected API format
    userInformation.sendInvite = formData.inviteClient ? 'yes' : 'no';

    // Handle edit or create based on context
    if (client) {
      if (impersonateTokenSuccess && impersonateToken) {
        const data = {
          _id: client?.connectionManagerMeta._id,
          ...userInformation,
          impersonatingUser: true,
        };
        editClient(data);
      }
    } else {
      dispatch(actions.createImpersonateUser(userInformation));
    }
  };

  // Close modal on successful edit/add
  useEffect(() => {
    if ((editUserSuccess && client) || clientAdditionSuccess) {
      onClose();
    }
  }, [client, editUserSuccess, onClose, clientAdditionSuccess]);

  // Determine button text based on the mode
  const buttonText = isAddNewClient
    ? t('advisors.addnew')
    : isEditClient
      ? t('continueButton')
      : '';

  // Determine title based on the current mode
  const getTitle = () => {
    if (viewClient) {
      return t('advisors.viewClient');
    } else if (client) {
      return t('advisors.editClient');
    } else {
      return t('advisors.addnew');
    }
  };

  return (
    <Box sx={style.container}>
      <Typography variant="h6" sx={style.title}>
        {getTitle()}
      </Typography>
      <Divider sx={style.divider} />
      <Typography variant="body1" sx={style.subheading}>
        {t('advisors.clientInfo')}
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={style.formContainer}
      >
        <ClientInformationForm
          useFormInstance={useFormInstance}
          editUserInfo={viewClient}
          clientInformation={client?.connectionManagerMeta}
          inviteClient={inviteClient}
          showInviteOption={!viewClient}
          handleInviteChange={handleInviteChange}
        />

        {/* Buttons - only show when not in view mode */}
        {!viewClient && (
          <Box sx={style.buttonContainer}>
            <Box component="button" onClick={onClose} sx={style.cancelButton}>
              {t('cancelButton')}
            </Box>
            <Box
              component="button"
              type="submit"
              disabled={!isValid}
              sx={style.submitButton}
            >
              {buttonText}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
