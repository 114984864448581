import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './style';
import { AdvisorCard } from '../AdvisorCard';
import { ImpersonateUserConnection } from 'app/features/AddressBookSlice/types';

interface Props {
  clientList: ImpersonateUserConnection[] | undefined;
  editOnClick?: (client: ImpersonateUserConnection) => () => void;
  deleteOnClick?: (client: ImpersonateUserConnection) => () => void;
  viewConsolidation?: (client: ImpersonateUserConnection) => () => void;
  viewOnClick?: (client: ImpersonateUserConnection) => () => void;
}

export function AdvisorTable({
  clientList,
  viewOnClick,
  viewConsolidation,
  editOnClick,
  deleteOnClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <Box sx={style.boxStyles}>
      <Box sx={style.containerSx}>
        {/* Table Header */}
        <Grid container sx={style.headerStakeholderStyles}>
          <Grid item xs={4} sx={style.headerNameColumn}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={style.headerText}
            >
              {t('name')}
            </Typography>
          </Grid>
          <Grid item xs={5} sx={style.headerContactColumn}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={style.headerText}
            >
              {t('contactDetails')}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={style.headerOptionsColumn}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={style.headerText}
            >
              {t('options')}
            </Typography>
          </Grid>
        </Grid>

        {/* Table Rows */}
        {clientList?.map((client, index) => (
          <Box key={client?._id || index}>
            <AdvisorCard
              groupIdList={[]}
              tags={[]}
              targetUserMeta={{
                _id: '',
                email: '',
                firstName: undefined,
                lastName: undefined,
                phoneNumber: undefined,
                countryCode: undefined,
                country: undefined,
                address: undefined,
                company: undefined,
                title: undefined,
              }}
              {...client}
              key={client._id}
              viewOnClick={viewOnClick && viewOnClick(client)}
              viewConsolidation={viewConsolidation && viewConsolidation(client)}
              editOnClick={editOnClick && editOnClick(client)}
              deleteOnClick={deleteOnClick && deleteOnClick(client)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
