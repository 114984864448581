import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Stack,
  Chip,
} from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { MoreVert } from '@mui/icons-material';
import { formatPhoneNumber } from 'utils/formats';
import style from './style';
import { ContactSummary } from 'app/features/AddressBookSlice/types';
import { useUser } from 'providers/UserProvider';

type Props = {
  manageOnClick?: () => void;
  accessOnClick?: () => void;
  deleteOnClick?: () => void;
} & ContactSummary;

export function StakeholderCard({
  accessList,
  targetUserId,
  targetUserMeta,
  accessOnClick,
  deleteOnClick,
  manageOnClick,
  tags,
}: Props) {
  const { email, lastName, firstName, phoneNumber, countryCode } =
    targetUserMeta;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { user } = useUser();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container key={targetUserId} sx={style.cardStyles}>
      <Grid item xs={3}>
        <Typography
          variant="body1"
          color="black.100"
          textTransform="capitalize"
        >
          {firstName} {lastName}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        {email && (
          <Box>
            <Typography variant="caption" color="text.secondary">
              {email}
            </Typography>
          </Box>
        )}
        {phoneNumber && (
          <Box>
            <Typography variant="caption" color="text.disabled">
              {formatPhoneNumber(phoneNumber, countryCode)}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={2}>
        {tags && tags.length > 0 ? (
          <Stack direction="row" spacing={1} sx={style.chipStackStyles}>
            {tags.map(roleTag => (
              <Chip key={roleTag} label={roleTag} size="small" />
            ))}
          </Stack>
        ) : (
          <Typography variant="body2" color="text.disabled">
            {t('common.emptyValue', { defaultValue: '—' })}
          </Typography>
        )}
      </Grid>

      <Grid item xs={3}>
        {accessList && accessList.length > 0 ? (
          <Stack direction="row" spacing={1} sx={style.chipStackStyles}>
            {accessList.map(access => (
              <Chip key={access} label={access} size="small" />
            ))}
          </Stack>
        ) : (
          <Typography variant="body2" color="text.disabled">
            {t('common.emptyValue', { defaultValue: '—' })}
          </Typography>
        )}
      </Grid>

      <Grid item xs={1} sx={style.boxButtons}>
        {targetUserId !== user?._id ? (
          <>
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
              {manageOnClick && (
                <MenuItem onClick={manageOnClick} sx={style.menuItemStyles}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2">
                      {capitalize(t('stakeholder.button.manageAccess'))}
                    </Typography>
                  </Stack>
                </MenuItem>
              )}
              {accessOnClick && (
                <MenuItem onClick={accessOnClick} sx={style.menuItemStyles}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2">
                      {capitalize(t('stakeholder.button.viewEditDetails'))}
                    </Typography>
                  </Stack>
                </MenuItem>
              )}
              {deleteOnClick && (
                <MenuItem onClick={deleteOnClick} sx={style.menuItemStyles}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2">
                      {capitalize(t('stakeholder.button.delete'))}
                    </Typography>
                  </Stack>
                </MenuItem>
              )}
            </Menu>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
